import React from 'react'
import { DATA_TEST_ID } from 'shared-constants/build/testIds'
import Icon from 'components/Icon'
import { Flex } from 'components/Layout'
import Link from 'components/Link'
import { HeaderLogo } from 'components/Logo'
import { HEADER_HEIGHT_MOBILE_PARTIAL } from 'constants/common'
import { ROUTE } from 'constants/routes'
import { COLOR_INTENT } from 'Theme'
import { useMedia } from 'useMedia'
import { LOGO_MOBILE_DIMENSIONS } from '../constants'
import { MenuIconWrapper } from './MenuIconWrapper'
import { MobileMenu } from './MobileMenu'

interface MobileNavigationProps {
  isMobileMenuOpen: boolean
  searchComponent: React.ReactNode
  iconsMenu: React.ReactNode
  isSearchDropdownVisible: boolean
  setIsSearchDropdownVisible: React.Dispatch<React.SetStateAction<boolean>>
  searchString: string
  onMenuClose: () => void
  setIsMobileMenuOpen: React.Dispatch<React.SetStateAction<boolean>>
  navigationItems: any
  onSearchDropdownClose: () => void
}

export const MobileNavigation: React.FC<React.PWC<MobileNavigationProps>> = ({
  searchComponent,
  isMobileMenuOpen,
  iconsMenu,
  isSearchDropdownVisible,
  setIsSearchDropdownVisible,
  searchString,
  onMenuClose,
  setIsMobileMenuOpen,
  navigationItems,
  onSearchDropdownClose,
}) => {
  const { MOBILE: isMobile } = useMedia()

  const isMenuCrossIconShown = isMobileMenuOpen
  const isSearchCrossIconShown = isSearchDropdownVisible && searchString !== ''

  return (
    <Flex flexDirection="column" height="100%">
      <Flex
        minHeight={HEADER_HEIGHT_MOBILE_PARTIAL}
        width="100%"
        alignItems="center"
        justifyContent="space-between"
      >
        <Link display="inline-flex" href={ROUTE.FRONT_PAGE()} aria-label="home">
          <HeaderLogo {...LOGO_MOBILE_DIMENSIONS} titleId="mobile-title" />
        </Link>
        <Flex>
          {iconsMenu}
          <MenuIconWrapper
            isMobile={isMobile}
            data-test-id={DATA_TEST_ID.HAMBURGER_BTN}
            onClick={() => {
              if (isMenuCrossIconShown) {
                onMenuClose()
                setIsSearchDropdownVisible(false)
              } else {
                setIsMobileMenuOpen(true)
              }
            }}
          >
            {isMenuCrossIconShown ? (
              <Icon.MenuCross color={COLOR_INTENT.HEADER.ICONS.DEFAULT} />
            ) : (
              <Icon.Hamburger color={COLOR_INTENT.HEADER.ICONS.DEFAULT} />
            )}
          </MenuIconWrapper>
        </Flex>
      </Flex>
      <Flex alignItems="center" minHeight={HEADER_HEIGHT_MOBILE_PARTIAL}>
        <Flex width="100%" alignItems="center">
          {searchComponent}
        </Flex>
        {isSearchCrossIconShown && (
          <MenuIconWrapper
            isMobile={isMobile}
            data-test-id={DATA_TEST_ID.HAMBURGER_BTN}
            onClick={() => {
              onMenuClose()
              onSearchDropdownClose()
              setIsSearchDropdownVisible(false)
            }}
          >
            <Icon.MenuCross color={COLOR_INTENT.HEADER.ICONS.DEFAULT} />
          </MenuIconWrapper>
        )}
      </Flex>
      <MobileMenu
        navigationItems={navigationItems}
        isMenuOpen={isMobileMenuOpen}
        onMenuClose={onMenuClose}
        isBorderVisible={isMobileMenuOpen}
      />
    </Flex>
  )
}
