import { AnimatePresence, motion } from 'framer-motion'
import { rem } from 'polished'
import React from 'react'
import styled, { css } from 'styled-components'
import { Box } from 'components/Layout'
import { COLOR_INTENT, FONT_SIZE, FONT_WEIGHT, RADIUS, SPACE } from 'Theme'

export const ICON_BADGE_VARIANT = {
  DEFAULT: 'default',
  MINIMAL: 'minimal',
} as const

export const ICON_BADGE_POSITION = {
  TOP_LEFT: 'top-left',
  TOP_RIGHT: 'top-right',
  BOTTOM_LEFT: 'bottom-left',
  BOTTOM_RIGHT: 'bottom-right',
} as const

export const ICON_BADGE_INTENT = {
  DEFAULT: 'default',
  DANGER: 'danger',
} as const

const COLORS = {
  [ICON_BADGE_INTENT.DEFAULT]: {
    background: COLOR_INTENT.ICON_BADGE.DEFAULT.BACKGROUND,
    color: COLOR_INTENT.ICON_BADGE.DEFAULT.TEXT,
  },
  [ICON_BADGE_INTENT.DANGER]: {
    background: COLOR_INTENT.ICON_BADGE.DANGER.BACKGROUND,
    color: COLOR_INTENT.ICON_BADGE.DANGER.TEXT,
  },
}

const SIZE = {
  [ICON_BADGE_VARIANT.DEFAULT]: rem(20),
  [ICON_BADGE_VARIANT.MINIMAL]: rem(8),
}

const OFFSET = {
  [ICON_BADGE_VARIANT.DEFAULT]: '0',
  [ICON_BADGE_VARIANT.MINIMAL]: '14%',
}

const ANIMATION_DURATION = 0.2
const ANIMATION_STATE = {
  INVISIBLE: 'invisible',
  VISIBLE: 'visible',
}

const getCssTranslate = (
  position: (typeof ICON_BADGE_POSITION)[keyof typeof ICON_BADGE_POSITION]
) => {
  switch (position) {
    case ICON_BADGE_POSITION.TOP_LEFT:
      return '-50%, -50%'
    case ICON_BADGE_POSITION.BOTTOM_LEFT:
      return '-50%, 50%'
    case ICON_BADGE_POSITION.BOTTOM_RIGHT:
      return '50%, 50%'
    default:
      return '50%, -50%'
  }
}

const getCssPosition = (
  position: (typeof ICON_BADGE_POSITION)[keyof typeof ICON_BADGE_POSITION],
  variant: (typeof ICON_BADGE_VARIANT)[keyof typeof ICON_BADGE_VARIANT]
) => {
  switch (position) {
    case ICON_BADGE_POSITION.TOP_LEFT:
      return `
        top: ${OFFSET[variant]};
        left: ${OFFSET[variant]};
        transform-origin: 0% 0%;
      `
    case ICON_BADGE_POSITION.BOTTOM_LEFT:
      return `
        bottom: ${OFFSET[variant]};
        left: ${OFFSET[variant]};
        transform-origin: 0% 100%;
      `
    case ICON_BADGE_POSITION.BOTTOM_RIGHT:
      return `
        bottom: ${OFFSET[variant]};
        right: ${OFFSET[variant]};
        transform-origin: 100% 100%;
      `
    default:
      // TOP_RIGHT
      return `
        top: ${OFFSET[variant]};
        right: ${OFFSET[variant]};
        transform-origin: 100% 0%;
      `
  }
}

const Badge = styled(motion.div)`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ position, variant }) =>
    position &&
    variant &&
    css`
      ${getCssPosition(position, variant)}
    `}
  ${({ variant }) =>
    variant === ICON_BADGE_VARIANT.MINIMAL
      ? css`
          height: ${SIZE[variant]};
          width: ${SIZE[variant]};
          border-radius: ${RADIUS.PX_4};
        `
      : css`
          height: ${SIZE[variant]};
          min-width: ${SIZE[variant]};
          padding: 0px ${SPACE.PX_5};
          border-radius: ${RADIUS.PX_10};
        `}
  ${({ intent }) =>
    intent &&
    css`
      background: ${COLORS[intent].background};
      color: ${COLORS[intent].color};
    `}
  font-size: ${FONT_SIZE.PX_12};
  font-weight: ${FONT_WEIGHT.MEDIUM};
  line-height: 1;
  white-space: nowrap;
  overflow: hidden;
`

interface IconBadgeProps {
  content: number | string
  variant?: (typeof ICON_BADGE_VARIANT)[keyof typeof ICON_BADGE_VARIANT]
  intent?: (typeof ICON_BADGE_INTENT)[keyof typeof ICON_BADGE_INTENT]
  position?: (typeof ICON_BADGE_POSITION)[keyof typeof ICON_BADGE_POSITION]
  max?: number
  showZero?: boolean
}

export const IconBadge: React.FC<React.PWC<IconBadgeProps>> = ({
  content,
  variant = ICON_BADGE_VARIANT.DEFAULT,
  intent = ICON_BADGE_INTENT.DEFAULT,
  position = ICON_BADGE_POSITION.TOP_RIGHT,
  max,
  showZero = false,
  children,
}) => (
  <Box position="relative" display="inline-block">
    {children}
    <AnimatePresence initial={false}>
      {content === '' || (Number(content) === 0 && !showZero) ? null : (
        <Badge
          variant={variant}
          intent={intent}
          position={position}
          initial={ANIMATION_STATE.INVISIBLE}
          animate={ANIMATION_STATE.VISIBLE}
          exit={ANIMATION_STATE.INVISIBLE}
          variants={{
            [ANIMATION_STATE.INVISIBLE]: {
              transform: `scale(0) translate(${getCssTranslate(position)})`,
            },
            [ANIMATION_STATE.VISIBLE]: {
              transform: `scale(1) translate(${getCssTranslate(position)})`,
            },
          }}
          transition={{ duration: ANIMATION_DURATION }}
        >
          {variant !== ICON_BADGE_VARIANT.MINIMAL &&
            (Number(content) > max ? `${max}+` : content)}
        </Badge>
      )}
    </AnimatePresence>
  </Box>
)
