import { debounce } from 'lodash'
import React, { useCallback } from 'react'
import { useSearchBox } from 'react-instantsearch'
import Icon, { ICON_SIZE } from 'components/Icon'
import { Input } from 'components/Input'
import { COLOR_INTENT } from 'Theme'
import { SEARCH_RESULT_DROPDOWN_ID } from './SearchResultsDropdown'
import { DEBOUNCE_DELAY, MIN_SEARCH_LENGTH } from './utils'

interface Props {
  searchString: string
  setSearchString: React.Dispatch<React.SetStateAction<string>>
  setIsDropdownVisible: React.Dispatch<React.SetStateAction<boolean>>
  onClick?: () => void
  onFocus?: () => void
  onBlur?: () => void
}

export const SearchInput = React.forwardRef<
  React.DetailedHTMLProps<
    React.LabelHTMLAttributes<HTMLLabelElement>,
    HTMLLabelElement
  >,
  Props
>(
  (
    {
      searchString,
      setSearchString,
      setIsDropdownVisible,
      onClick,
      onFocus,
      onBlur,
    },
    ref
  ) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const queryHook = useCallback(
      debounce((query, search) => {
        if (query.length < MIN_SEARCH_LENGTH) {
          return
        }
        search(query)
        setIsDropdownVisible(true)
      }, DEBOUNCE_DELAY),
      [setIsDropdownVisible]
    )
    const { refine } = useSearchBox({
      queryHook,
    })

    return (
      <Input
        ref={ref}
        name="search"
        placeholder="Search experts, categories, products or brands"
        aria-controls={SEARCH_RESULT_DROPDOWN_ID}
        aria-label="Global search input"
        // Removed because of calling useeffect each render as isDropdownVisible isnt setting on false and its issue, solution in https://github.com/sudolabs-io/the-expert/pull/7787/commits/9be481d7fb310ba8ff75290fa8d7a5b95f9bc562
        // aria-expanded={!!elementRef.current}
        icon={Icon.MenuSearch}
        iconColor={COLOR_INTENT.SEARCH.INPUT_ICON}
        iconSize={ICON_SIZE.PX_20}
        onClick={onClick}
        value={searchString}
        role="combobox"
        onChange={({ target: { value } }) => {
          if (value.length < MIN_SEARCH_LENGTH) {
            setIsDropdownVisible(false)
          }
          setSearchString(value)
          refine(value)
        }}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    )
  }
)
