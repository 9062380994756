import React, { useRef } from 'react'
import styled, { css } from 'styled-components'
import { type SpaceProps } from 'styled-system'
import Icon, { ICON_SIZE } from 'components/Icon'
import { Flex } from 'components/Layout'
import Link from 'components/Link'
import { Text } from 'components/Typography'
import { useIsOverflow } from 'hooks'
import { COLOR_INTENT, FONT_SIZE, FONT_WEIGHT, LINE_HEIGHT, SPACE } from 'Theme'

export const BREADCRUMBS_VARIANT = {
  PRIMARY: 'PRIMARY',
  CHECKOUT: 'CHECKOUT',
} as const

const BreadcrumbText = styled(Text).attrs((props) => ({
  disabled: props.isDisabled,
}))`
  ${({ variant }) => {
    const defaultStyle = css`
      cursor: pointer;
      color: ${COLOR_INTENT.BREADCRUMBS[variant].VISITED};

      ${({ isDisabled }) =>
        isDisabled &&
        css`
          color: ${COLOR_INTENT.BREADCRUMBS[variant].DISABLED};
          cursor: not-allowed;
        `}
      ${({ isActive }) =>
        isActive &&
        css`
          color: ${COLOR_INTENT.BREADCRUMBS[variant].ACTIVE};
          cursor: default;
        `}
    `

    switch (variant) {
      case BREADCRUMBS_VARIANT.PRIMARY:
        return css`
          ${defaultStyle};
          font-size: ${FONT_SIZE.PX_13};
          line-height: ${LINE_HEIGHT.XS};
          &:hover {
            color: ${COLOR_INTENT.BREADCRUMBS.PRIMARY.ACTIVE};
            text-decoration: underline;
          }
        `
      case BREADCRUMBS_VARIANT.CHECKOUT:
        return css`
          ${defaultStyle};
          line-height: ${LINE_HEIGHT.XL};
          font-weight: ${FONT_WEIGHT.MEDIUM};
        `
      default:
        return defaultStyle
    }
  }};
`

const BreadcrumbsWrapper = styled(Flex)`
  overflow: auto;
  white-space: nowrap;
  > * {
    flex-shrink: 0;
  }
`

interface BreadcrumbsProps extends SpaceProps {
  variant?: (typeof BREADCRUMBS_VARIANT)[keyof typeof BREADCRUMBS_VARIANT]
  steps: {
    label: string
    ariaLabel?: string
    link: string
    disabled?: boolean
  }[]
  activeStepIndex?: number
}

export const Breadcrumbs: React.FC<React.PWC<BreadcrumbsProps>> = ({
  variant = BREADCRUMBS_VARIANT.PRIMARY,
  steps,
  activeStepIndex,
  ...rest
}) => {
  const breadcrumbsWrapperRef = useRef<HTMLDivElement>()
  useIsOverflow(breadcrumbsWrapperRef, (isOverflow) => {
    if (breadcrumbsWrapperRef?.current && isOverflow) {
      breadcrumbsWrapperRef.current.scroll({
        left: breadcrumbsWrapperRef.current.scrollWidth,
      })
    }
  })

  return (
    <BreadcrumbsWrapper
      ref={breadcrumbsWrapperRef}
      flexShrink="0"
      alignItems="center"
      gap={SPACE.PX_4}
      {...rest}
    >
      {steps.map(({ label, link, disabled, ariaLabel = null }, index) => {
        const isActive = index === activeStepIndex
        const isDisabled = disabled || index > activeStepIndex
        return (
          <React.Fragment key={link}>
            <Link
              href={link}
              disabled={isActive || isDisabled}
              aria-label={ariaLabel ?? label}
            >
              <BreadcrumbText
                isDisabled={isDisabled}
                isActive={isActive}
                variant={variant}
              >
                {label}
              </BreadcrumbText>
            </Link>

            {index < steps.length - 1 && (
              <Icon.ChevronRightSmall
                size={ICON_SIZE.PX_16}
                color={COLOR_INTENT.BREADCRUMBS[variant].ICON}
              />
            )}
          </React.Fragment>
        )
      })}
    </BreadcrumbsWrapper>
  )
}
