import React from 'react'
import styled, { css } from 'styled-components'
import { type FlexboxProps } from 'styled-system'
import { Box, Flex } from 'components/Layout'
import { LinkWithText, type LinkWithTextProps } from 'components/Link'
import {
  BORDER_WIDTH,
  COLOR_INTENT,
  COLOR_NAMES,
  FONT_SIZE,
  FONT_WEIGHT,
  SPACE,
} from 'Theme'

interface WrapperProps {
  isActive: boolean
  hasSubMenu?: boolean
}

interface NavigationItemProps
  extends WrapperProps,
    LinkWithTextProps,
    FlexboxProps {
  displayBetaLabel?: boolean
}

const Wrapper = styled(Flex)`
  white-space: nowrap;
  cursor: pointer;
  border-bottom: ${BORDER_WIDTH.PX_2} solid transparent;
  border-top: ${BORDER_WIDTH.PX_2} solid transparent;
  a {
    color: ${COLOR_INTENT.MENU.TEXT_LINK.DEFAULT};
  }
  ${({ isActive, hasSubMenu }) =>
    isActive &&
    hasSubMenu &&
    css`
      border-bottom-color: ${COLOR_INTENT.MENU.TEXT_LINK.HOVER};
    `}
  &:hover {
    a {
      color: ${COLOR_INTENT.MENU.TEXT_LINK.HOVER};
    }
  }
`

export const NavigationItem: React.FC<React.PWC<NavigationItemProps>> = ({
  children,
  href,
  displayBetaLabel,
  ...rest
}) => (
  <Wrapper {...rest} alignItems="center" height="100%">
    <LinkWithText fontSize={FONT_SIZE.PX_15} href={href}>
      {children}
    </LinkWithText>
    {displayBetaLabel && (
      <Box margin={`0 0 ${SPACE.PX_10} ${SPACE.PX_4}`}>
        <LinkWithText
          href={href}
          fontWeight={FONT_WEIGHT.MEDIUM}
          fontSize={FONT_SIZE.PX_10}
          color={COLOR_NAMES.PINEY_LAKE}
        >
          BETA
        </LinkWithText>
      </Box>
    )}
  </Wrapper>
)
