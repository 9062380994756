import React from 'react'
import Icon, { ICON_SIZE } from 'components/Icon'
import { Flex } from 'components/Layout'
import Link from 'components/Link'
import { SPACE, COLOR_INTENT } from 'Theme'
import { useMedia } from 'useMedia'

export const SocialLinks = () => {
  const { MOBILE: isMobile } = useMedia()
  const ICON_SIZE_VALUE = isMobile ? ICON_SIZE.PX_24 : ICON_SIZE.PX_20

  return (
    <Flex
      width={{ MOBILE: '100%', TABLET: 'fit-content' }}
      justifyContent={{ MOBILE: 'center', TABLET: 'flex-end' }}
      gap={{ MOBILE: SPACE.PX_32, TABLET: SPACE.PX_20 }}
    >
      <Link
        href="https://www.pinterest.com/theexpertcom"
        target="_blank"
        aria-label="Pinterest - link opens in a new tab"
      >
        <Icon.Pinterest
          size={ICON_SIZE_VALUE}
          color={COLOR_INTENT.FOOTER.SOCIAL_LINKS}
        />
      </Link>
      <Link
        href="https://instagram.com/theexpert"
        target="_blank"
        aria-label="Instagram - link opens in a new tab"
      >
        <Icon.Instagram
          size={ICON_SIZE_VALUE}
          color={COLOR_INTENT.FOOTER.SOCIAL_LINKS}
        />
      </Link>
      <Link
        href="https://www.facebook.com/thexpertcom"
        target="_blank"
        aria-label="Facebook - link opens in a new tab"
      >
        <Icon.Facebook
          size={ICON_SIZE_VALUE}
          color={COLOR_INTENT.FOOTER.SOCIAL_LINKS}
        />
      </Link>
    </Flex>
  )
}
