import { AnimatePresence, motion } from 'framer-motion'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import React, { useEffect, useRef, useState } from 'react'
import { useClickAway } from 'react-use'
import { ROUTE } from 'shared-constants'
import { useUserAuth } from 'providers/userAuth'
import { SPACE } from 'Theme'
import { type NavigationItem as NavigationItemType } from 'types/graphql-generated'
import { ANIMATION_VARIANT } from '../constants'
import {
  DROPDOWN_MENU_OVERLAY_TRANSITION_DURATION_IN_S,
  DropdownMenuOverlay,
} from './DropdownMenuOverlay'
import { NavigationItem } from './NavigationItem'
import { type DesktopMenuProps } from './types'

const DesktopMenu = dynamic<DesktopMenuProps>(
  () => import('./DesktopMenu').then((module) => module.DesktopMenu),
  {
    ssr: false,
  }
)

const MENU_OVERLAY_VARIANTS = {
  [ANIMATION_VARIANT.OPEN]: {
    opacity: 0.4,
    display: 'block',
  },
  [ANIMATION_VARIANT.CLOSE]: {
    opacity: 0,
    transitionEnd: {
      display: 'none',
    },
  },
}

interface DesktopNavItemProps {
  title: string
  href: string
  dropDownMenu?: Array<NavigationItemType>
  displayBetaLabel?: boolean
}

export const DesktopNavItem: React.FC<React.PWC<DesktopNavItemProps>> = ({
  title,
  href,
  dropDownMenu = [],
  displayBetaLabel,
}) => {
  const [isHover, setIsHover] = useState(false)
  const router = useRouter()
  const { isAdminLoggedInAsExpert, isAdminLoggedInAsClient } = useUserAuth()
  const menuRef = useRef(null)
  const hasSubMenu = dropDownMenu.length > 0

  const handleRouteChange = () => {
    setIsHover(false)
  }

  useEffect(() => {
    router.events.on('routeChangeComplete', handleRouteChange)
    router.events.on('routeChangeError', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
      router.events.off('routeChangeError', handleRouteChange)
    }
  }, [router])

  useClickAway(menuRef, () => {
    if (menuRef.current?.contains(document.activeElement)) {
      setIsHover(false)
    }
  })

  const isTradeProgramRibbonHidden =
    router.pathname === ROUTE.TRADE() ||
    process.env.NEXT_PUBLIC_NODE_ENV !== 'production'

  const isUserImpersonifiedAdmin =
    isAdminLoggedInAsExpert || isAdminLoggedInAsClient

  return (
    <AnimatePresence>
      <motion.div
        key="navigation-items"
        onHoverStart={() => {
          setIsHover(true)
        }}
        onHoverEnd={() => {
          if (!menuRef.current?.contains(document.activeElement)) {
            setIsHover(false)
          }
        }}
        ref={menuRef}
      >
        <NavigationItem
          isActive={isHover}
          href={href}
          mr={SPACE.PX_15}
          hasSubMenu={hasSubMenu}
          displayBetaLabel={displayBetaLabel}
        >
          {title}
        </NavigationItem>
        <DesktopMenu
          dropDownMenu={dropDownMenu}
          isVisible={isHover && hasSubMenu}
        />
      </motion.div>
      {hasSubMenu && (
        <DropdownMenuOverlay
          key="dropdown-menu"
          variants={MENU_OVERLAY_VARIANTS}
          initial={ANIMATION_VARIANT.CLOSE}
          exit={ANIMATION_VARIANT.CLOSE}
          animate={isHover ? ANIMATION_VARIANT.OPEN : ANIMATION_VARIANT.CLOSE}
          transition={{
            ease: 'linear',
            duration: DROPDOWN_MENU_OVERLAY_TRANSITION_DURATION_IN_S,
          }}
          isTradeProgramRibbonHidden={isTradeProgramRibbonHidden}
          isUserImpersonifiedAdmin={isUserImpersonifiedAdmin}
        />
      )}
    </AnimatePresence>
  )
}
