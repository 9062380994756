import { useQuery } from '@apollo/client'
import {
  clearAllBodyScrollLocks,
  disableBodyScroll,
  enableBodyScroll,
} from 'body-scroll-lock'
import { type Hit } from 'instantsearch.js'
import { get } from 'lodash'
import { useRouter } from 'next/router'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useWindowScroll } from 'react-use'
import { Container } from 'components/Layout'
import { MediaQueryWrapper } from 'components/MediaQueryWrapper'
import { Search } from 'components/Search'
import { useIsVisible, useUnloadPage } from 'hooks'
import { useCart } from 'providers/cart'
import {
  GA4_EVENTS,
  GA4_SEARCH_TYPE,
  useGoogleAnalytics,
} from 'providers/googleAnalytics'
import { useUserAuth } from 'providers/userAuth'
import {
  type NavigationItem,
  PublishedNavigationGroupsDocument as NAVIGATION_GROUP_QUERY,
} from 'types/graphql-generated'
import { useMedia } from 'useMedia'
import { DesktopNavigation } from './DesktopNavigation'
import { HeaderWrapper } from './HeaderWrapper'
import { IconsMenu } from './IconsMenu'
import { MobileNavigation } from './MobileNavigation'

// Function from body-scroll-lock package documentation
// https://github.com/willmcpo/body-scroll-lock#more-complex
const allowTouchMoveFn = (element: HTMLElement | Element) => {
  let targetElement = element
  while (targetElement && targetElement !== document.body) {
    if (targetElement.getAttribute('body-scroll-lock-ignore') !== null) {
      return true
    }

    targetElement = targetElement.parentElement
  }
  return false
}

interface NavigationProps {
  navigation?: NavigationItem[]
}

export const Navigation: React.FC<React.PWC<NavigationProps>> = ({
  navigation = [],
}) => {
  const { cart } = useCart()
  const router = useRouter()
  const { MOBILE: isMobile } = useMedia()
  const userAuth = useUserAuth()
  const { executeDataToDataLayer } = useGoogleAnalytics()
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)
  const [searchString, setSearchString] = useState('')
  const currentSearchData = useRef<{ searchValue: string } | null>(null)
  const [isSearchInputFocused, setIsSearchInputFocused] = useState(false)

  const { y } = useWindowScroll()
  const isScrolled = y > 0

  const {
    ref: searchDropdownRef,
    isVisible: isSearchDropdownVisible,
    setIsVisible: setIsSearchDropdownVisible,
  } = useIsVisible({
    initialIsVisible: false,
    isOutsideClickDisabled: isMobile,
    whitelistOutsideClickSiblingElements: true,
  })

  const { data: fetchedNavigation } = useQuery(NAVIGATION_GROUP_QUERY, {
    fetchPolicy: 'cache-first',
    skip: Boolean(navigation.length),
  })

  const isDropdowVisibleWithSearchValue =
    isSearchDropdownVisible && searchString.length

  const onMenuClose = useCallback(() => {
    if (isMobile) {
      enableBodyScroll(document.querySelector('body'))

      setIsMobileMenuOpen(false)

      if (currentSearchData.current) {
        executeDataToDataLayer({
          event: GA4_EVENTS.SEARCH,
          overrideData: {
            type: GA4_SEARCH_TYPE.LOST,
            searchData: currentSearchData.current,
          },
        })

        currentSearchData.current = null
      }
    }
  }, [isMobile, executeDataToDataLayer])

  useEffect(() => {
    if (isDropdowVisibleWithSearchValue && isMobile) {
      window.scrollTo(0, 0)
    }
  }, [isDropdowVisibleWithSearchValue, isMobile])

  useEffect(() => {
    if (isMobile) {
      if (isSearchDropdownVisible && searchString) {
        disableBodyScroll(document.querySelector('body'), {
          allowTouchMove: allowTouchMoveFn,
        })
      } else if (isMobileMenuOpen) {
        disableBodyScroll(document.querySelector('body'), {
          allowTouchMove: allowTouchMoveFn,
        })
      } else {
        onMenuClose()
      }
    }
  }, [
    isSearchDropdownVisible,
    searchDropdownRef,
    searchString,
    isMobile,
    onMenuClose,
    isMobileMenuOpen,
  ])

  let navigationList = navigation ?? []

  if (fetchedNavigation) {
    const { publishedNavigationGroups } = fetchedNavigation
    navigationList = publishedNavigationGroups.map((navigationGroup) =>
      get(navigationGroup, ['publishedNavigation', 'rootItem'])
    )
  }

  useEffect(() => {
    // Execute data to dataLayer when search dropdown gets hidden
    if (
      !isDropdowVisibleWithSearchValue &&
      searchString.length &&
      currentSearchData.current
    ) {
      executeDataToDataLayer({
        event: GA4_EVENTS.SEARCH,
        overrideData: {
          type: GA4_SEARCH_TYPE.LOST,
          searchData: currentSearchData.current,
        },
      })

      currentSearchData.current = null
    }
  }, [executeDataToDataLayer, isDropdowVisibleWithSearchValue, searchString])

  useUnloadPage(() => {
    if (currentSearchData.current) {
      executeDataToDataLayer({
        event: GA4_EVENTS.SEARCH,
        overrideData: {
          type: GA4_SEARCH_TYPE.LOST,
          searchData: currentSearchData.current,
        },
      })
    }
  })

  const onNewSearch = (query: string) => {
    if (query) {
      // Data from the state before deleting whole search or last letter
      const previousSearchData = currentSearchData.current
      currentSearchData.current = { searchValue: query }

      if (previousSearchData?.searchValue?.length) {
        executeDataToDataLayer({
          event: GA4_EVENTS.SEARCH,
          overrideData: {
            type: GA4_SEARCH_TYPE.LOST,
            searchData: previousSearchData,
          },
        })
      }
    }
  }

  const onOptionSelect = (hit: Hit) => {
    setIsSearchInputFocused(false)
    currentSearchData.current = null
    if (!hit) {
      return
    }
    router.push(hit.url)

    setSearchString('')
    setIsSearchDropdownVisible(false)
    clearAllBodyScrollLocks()

    executeDataToDataLayer({
      event: GA4_EVENTS.SEARCH,
      overrideData: {
        type: GA4_SEARCH_TYPE.SUGGEST,
        searchData: {
          searchValue: searchString,
        },
      },
    })
  }

  const onSearchInputFocus = useCallback(() => {
    setIsSearchInputFocused(true)
  }, [])

  const onSearchInputBlur = useCallback(() => {
    if (!(isSearchDropdownVisible && searchString !== '')) {
      setIsSearchInputFocused(false)
    }
  }, [isSearchDropdownVisible, searchString])

  const search = (
    <Search
      ref={searchDropdownRef}
      searchString={searchString}
      setSearchString={setSearchString}
      isDropdownVisible={isSearchDropdownVisible}
      setIsDropdownVisible={setIsSearchDropdownVisible}
      onOptionSelect={onOptionSelect}
      onNewSearch={onNewSearch}
      isSearchInputFocused={isSearchInputFocused}
      onInputFocus={onSearchInputFocus}
      onInputBlur={onSearchInputBlur}
    />
  )

  const cartItemsCount = get(cart, ['summary', 'itemsCount'], 0)

  const iconsMenu = (
    <IconsMenu
      cartItemsCount={cartItemsCount}
      isMobileMenuOpen={isMobileMenuOpen}
      closeMenu={onMenuClose}
      isMobile={isMobile}
      isClientSignedIn={get(userAuth, 'isClientSignedIn', false)}
      isExpertSignedIn={get(userAuth, 'isExpertSignedIn', false)}
    />
  )

  const commonDesktopNavigationProps = {
    navigationItems: navigationList,
    searchComponent: search,
    iconsMenu,
  }

  return (
    <HeaderWrapper
      showShadow={isScrolled}
      isSearchInputFocused={isSearchInputFocused}
    >
      <Container height="100%">
        <MediaQueryWrapper
          mobile={
            <MobileNavigation
              searchComponent={search}
              isMobileMenuOpen={isMobileMenuOpen}
              iconsMenu={iconsMenu}
              isSearchDropdownVisible={isSearchDropdownVisible}
              setIsSearchDropdownVisible={setIsSearchDropdownVisible}
              searchString={searchString}
              onSearchDropdownClose={() => {
                enableBodyScroll(document.querySelector('body'))
                setSearchString('')
                setIsSearchInputFocused(false)
              }}
              onMenuClose={onMenuClose}
              setIsMobileMenuOpen={setIsMobileMenuOpen}
              navigationItems={navigationList}
            />
          }
          tablet={
            <DesktopNavigation
              {...commonDesktopNavigationProps}
              headerLogoTitleId="tablet-title"
            />
          }
          desktop={
            <DesktopNavigation
              {...commonDesktopNavigationProps}
              headerLogoTitleId="desktop-title"
            />
          }
          tv={
            <DesktopNavigation
              {...commonDesktopNavigationProps}
              headerLogoTitleId="tv-title"
            />
          }
        />
      </Container>
    </HeaderWrapper>
  )
}
