import { clearAllBodyScrollLocks } from 'body-scroll-lock'
import React, { useEffect } from 'react'
import { DATA_TEST_ID } from 'shared-constants/build/testIds'
import styled from 'styled-components'
import { Box, Container } from 'components/Layout'
import NotVerifiedEmailHeader from 'components/NotVerifiedEmailHeader'
import { SignedInAsUserHeader } from 'components/SignedInAsUserHeader'
import { useUserAuth } from 'providers/userAuth'
import { COLOR_INTENT, Z_INDEX } from 'Theme'
import { type NavigationItem } from 'types/graphql-generated'
import { Navigation } from './components/Navigation'

const SignedInAsExpertContainer = styled(Container)`
  background-color: ${COLOR_INTENT.HEADER.WRAPPER};
  margin: auto;
  padding: 0;
`

export const Header: React.FC<
  React.PWC<{ navigation: Array<NavigationItem> }>
> = ({ navigation }) => {
  const userAuth = useUserAuth()
  useEffect(clearAllBodyScrollLocks, [])

  return (
    <Box
      position="sticky"
      top={0}
      width="inherit"
      zIndex={Z_INDEX.NAVBAR}
      data-test-id={DATA_TEST_ID.HEADER}
      backgroundColor={COLOR_INTENT.HEADER.WRAPPER}
    >
      {userAuth?.user &&
        !userAuth?.user?.isEmailVerified &&
        userAuth.isClientSignedIn && (
          <Box backgroundColor={COLOR_INTENT.CALLOUT.DANGER.BACKGROUND}>
            <SignedInAsExpertContainer>
              <NotVerifiedEmailHeader />
            </SignedInAsExpertContainer>
          </Box>
        )}
      {(userAuth?.isAdminLoggedInAsExpert ||
        userAuth?.isAdminLoggedInAsClient) && (
        <Box backgroundColor={COLOR_INTENT.CALLOUT.PRIMARY.BACKGROUND}>
          <SignedInAsExpertContainer>
            <SignedInAsUserHeader />
          </SignedInAsExpertContainer>
        </Box>
      )}
      <Navigation navigation={navigation} />
    </Box>
  )
}
