import React from 'react'
import { BUTTON_VARIANT, Button } from 'components/Button'
import Icon, { ICON_SIZE } from 'components/Icon'
import { Flex } from 'components/Layout'
import Link from 'components/Link'
import Logo from 'components/Logo'
import { MediaQueryWrapper } from 'components/MediaQueryWrapper'
import { environment } from 'constants/environment'
import { ROUTE } from 'constants/routes'
import { FONT_WEIGHT, SPACE } from 'Theme'
import { HeaderWrapper } from './components/HeaderWrapper'
import { LOGO_MOBILE_DIMENSIONS } from './constants'

export const CheckoutHeader: React.FC<React.PWC<unknown>> = () => {
  const handleClickNeedHelp = () => {
    if (environment.CUSTOMER_SERVICE_FRAMEWORK === 'intercom') {
      window?.Intercom?.('show')
    } else {
      window?.FrontChat?.('show')
    }
  }

  return (
    <HeaderWrapper isCheckoutHeader>
      <Flex
        mx={SPACE.PX_15}
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        <Link href={ROUTE.FRONT_PAGE()} aria-label="home">
          <MediaQueryWrapper
            mobile={<Logo {...LOGO_MOBILE_DIMENSIONS} />}
            tablet={<Logo />}
          />
        </Link>
        <Button
          onClick={handleClickNeedHelp}
          aria-label="Need help?"
          variant={BUTTON_VARIANT.MINIMAL}
          width="max-content"
          fontWeight={FONT_WEIGHT.NORMAL}
          pr={SPACE.PX_24}
        >
          <Icon.CircleQuestionMark size={ICON_SIZE.PX_36} />
          Need help?
        </Button>
      </Flex>
    </HeaderWrapper>
  )
}
