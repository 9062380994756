import React from 'react'
import styled from 'styled-components'
import {
  space,
  border,
  layout,
  type OverflowProps,
  type WidthProps,
  type BorderRadiusProps,
} from 'styled-system'

/* https://github.com/vercel/next.js/discussions/18739#discussioncomment-344932 */
const ImageWrapper = styled('div')`
  ${space}
  ${border}
  ${layout}

  & > span {
    position: unset !important;

    & > img {
      object-fit: contain;
      width: 100% !important;
      position: relative !important;
      height: unset !important;
    }
  }
`

interface FillImageWrapperProps
  extends WidthProps,
    OverflowProps,
    BorderRadiusProps {}

export const FillImageWrapper: React.FC<
  FillImageWrapperProps & Required<React.PropsWithChildren>
> = ({ children, ...props }) => (
  <ImageWrapper position="relative" width="100%" height="100%" {...props}>
    {children}
  </ImageWrapper>
)
