import { reduce } from 'lodash'

const generateAspectRatio = (width: number, height: number) => ({
  width,
  height,
  ratio: width / height,
})

export const ASPECT_RATIO_SIZE = {
  PROFILE: generateAspectRatio(1, 1),
  VENDOR_LOGO: generateAspectRatio(1, 1),
  GALLERY: generateAspectRatio(3, 2),
  VIDEO_THUMBNAIL: generateAspectRatio(2, 1),
  HERO: generateAspectRatio(9, 5),
  SPOTLIGHT: generateAspectRatio(5, 6),
  SINGLE_IMAGE: generateAspectRatio(4, 5),
  DOUBLE_IMAGE: generateAspectRatio(1, 1),
  PRODUCTS: generateAspectRatio(1, 1),
  PRODUCT_VARIANT: generateAspectRatio(1, 1),
  ARTICLE_PREVIEW_IMAGE: generateAspectRatio(1, 1),
  PURCHASE_IMAGE: generateAspectRatio(1, 1),
  CATEGORY_HEADER_SINGLE_IMAGE: generateAspectRatio(10, 7),
  CATEGORY_HEADER_CAROUSEL: generateAspectRatio(1, 1),
  COLLECTION_HEADER: generateAspectRatio(10, 3),
  COLLECTION_HEADER_MOBILE: generateAspectRatio(12, 5),
  INTRODUCTION: generateAspectRatio(6, 5),
  HEADER_ITEM_IMAGE: generateAspectRatio(14, 9),
  MENU_SUB_ITEM: generateAspectRatio(8, 5),
  CONSULTATION_RECAP_ATTACHMENT: generateAspectRatio(1, 1),
  MENU_THUMBNAIL: generateAspectRatio(472, 232),
  CARD_NAVIGATION: generateAspectRatio(1, 1),
  ALT_EXPERT_LISTING: generateAspectRatio(268, 380),
  ALT_VALUE_PROPOSITION_MOBILE: generateAspectRatio(768, 953),
  ALT_VALUE_PROPOSITION_TABLET: generateAspectRatio(708, 320),
  WIDGET_ITEM_COLUMN_CARD: generateAspectRatio(1, 1),
} as const

type AspectRatioReturnType = {
  [key in keyof typeof ASPECT_RATIO_SIZE]: number
}

export const ASPECT_RATIO = {
  FREE: null,
  ...reduce(
    ASPECT_RATIO_SIZE,
    (acc, value, key) => ({
      ...acc,
      [key]: value.ratio,
    }),
    {} as AspectRatioReturnType
  ),
} as const
