import React from 'react'
import styled from 'styled-components'
import { Flex } from 'components/Layout'
import { LinkWithText } from 'components/Link'
import { Text } from 'components/Typography'
import { TRADE_RIBBON_HEIGHT } from 'constants/common'
import { ROUTE } from 'constants/routes'
import { COLOR_INTENT, FONT_SIZE, FONT_STACK, SPACE, Z_INDEX } from 'Theme'

const StyledLinkWithText = styled(LinkWithText)`
  text-decoration: underline;
`

export const TradeProgramRibbon: React.FC<React.PWC<unknown>> = () => (
  <Flex
    px={{ MOBILE: SPACE.PX_6, TABLET: SPACE.PX_9 }}
    backgroundColor={COLOR_INTENT.TRADE_PROGRAM_RIBBON.BACKGROUND}
    alignItems="center"
    justifyContent="center"
    zIndex={Z_INDEX.TRADE_PROGRAM_RIBBON}
    height={TRADE_RIBBON_HEIGHT}
  >
    <Text
      color={COLOR_INTENT.TRADE_PROGRAM_RIBBON.TEXT}
      fontSize={{ MOBILE: FONT_SIZE.PX_13, TABLET: FONT_SIZE.PX_14 }}
      fontFamily={FONT_STACK.SANS}
      textAlign="center"
    >
      Are you a designer?{' '}
      <StyledLinkWithText
        color={COLOR_INTENT.TRADE_PROGRAM_RIBBON.TEXT}
        href={ROUTE.TRADE()}
        fontSize={{ MOBILE: FONT_SIZE.PX_13, TABLET: FONT_SIZE.PX_14 }}
      >
        Join our Trade program
      </StyledLinkWithText>
    </Text>
  </Flex>
)
