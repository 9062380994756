import NextImage from 'next/legacy/image'
import React, { useState } from 'react'
import { DATA_TEST_ID } from 'shared-constants/build/testIds'
import styled, { css, keyframes } from 'styled-components'
import { Box } from 'components/Layout'
import { ASPECT_RATIO } from 'components/UploadImage/constants'
import { COLOR_INTENT, IMAGE_BASE_WIDTH } from 'Theme'

const TRANSITION_TIME = '200ms'
const BLUR_IMAGE_WIDTH = 10

const pulseEffectAnimation = keyframes`
  0% { opacity: 0.3 }
  50% { opacity: 0.6 }
  100% { opacity: 0.3 }
`

const StyledNextImage = styled(NextImage)`
  ${({ isDisabled }) =>
    isDisabled
      ? css`
          filter: grayscale(100%);
          opacity: 0.75;
        `
      : ``};
  transition: ${TRANSITION_TIME};
`

export const PLACEHOLDER = {
  [ASPECT_RATIO.FREE]: '/imagePlaceholder.svg',
  [ASPECT_RATIO.HEADER_ITEM_IMAGE]: '/imagePlaceholder.svg',
  [ASPECT_RATIO.ARTICLE_PREVIEW_IMAGE]: '/imagePlaceholder.svg',
  [ASPECT_RATIO.PROFILE]: '/imagePlaceholder.svg',
  [ASPECT_RATIO.VENDOR_LOGO]: '/imagePlaceholder.svg',
  [ASPECT_RATIO.GALLERY]: '/imagePlaceholderGallery.svg',
  [ASPECT_RATIO.VIDEO_THUMBNAIL]: '/imagePlaceholderThumbnail.svg',
  [ASPECT_RATIO.HERO]: '/imagePlaceholderHero.svg',
  [ASPECT_RATIO.SPOTLIGHT]: '/imagePlaceholderSpotlight.svg',
  [ASPECT_RATIO.SINGLE_IMAGE]: '/imagePlaceholderSingleImage.svg',
  [ASPECT_RATIO.PRODUCTS]: '/imagePlaceholder.svg',
}

export const IMAGE_LAYOUT = {
  INTRINSIC: 'intrinsic',
  FIXED: 'fixed',
  RESPONSIVE: 'responsive',
  FILL: 'fill',
}

export const LOADING_POLICY = {
  EAGER: 'eager',
  LAZY: 'lazy',
}

const SkeletonImage = styled(Box)`
  ${({ layout, height }) =>
    layout === IMAGE_LAYOUT.FILL
      ? css`
          position: absolute;
          min-height: 100%;
          max-height: 100%;
          inset: 0;
        `
      : css`
          position: relative;
          ${layout !== IMAGE_LAYOUT.FIXED && `width: 100%;`}
          ${[IMAGE_LAYOUT.RESPONSIVE, IMAGE_LAYOUT.INTRINSIC].includes(
            layout
          ) &&
          `
            height: 100%;
            max-height: ${height}px;
            padding-top: 100%;
          `}
        `}

  background-color: ${COLOR_INTENT.SKELETON.FOREGROUND};

  &::after {
    content: '';
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    background-color: ${COLOR_INTENT.SKELETON.BACKGROUND};
    animation: ${pulseEffectAnimation} 1.3s ease-out infinite;
  }
`

const appendWidthToUrl = (url: string, width: number) => {
  let urlObject: URL

  try {
    urlObject = new URL(url)
  } catch (error) {
    return url
  }

  urlObject.searchParams.append('width', width.toString())
  return urlObject.href
}

const imageLoader = ({ src, width, placeholderSrc }) => {
  if (src === placeholderSrc || !src) {
    return placeholderSrc
  }
  if (width) {
    return appendWidthToUrl(src, width)
  }
  return src
}

interface ImageProps
  extends Omit<React.ComponentProps<typeof NextImage>, 'src' | 'layout'> {
  isLoading?: boolean
  src?: string
  alt: string
  aspectRatio?: (typeof ASPECT_RATIO)[keyof typeof ASPECT_RATIO]
  layout?: (typeof IMAGE_LAYOUT)[keyof typeof IMAGE_LAYOUT]
  baseWidth?: number
  baseHeight?: number
  requestWidth?: number
  loadingPolicy?: (typeof LOADING_POLICY)[keyof typeof LOADING_POLICY]
  sizes?: string
  isDisabled?: boolean
}

const getResolution = ({
  layout,
  aspectRatio,
  baseWidth,
  baseHeight,
}: Pick<ImageProps, 'layout' | 'aspectRatio' | 'baseHeight' | 'baseWidth'>) => {
  let height: number
  let width: number
  if (layout !== IMAGE_LAYOUT.FILL) {
    width = baseWidth
    height = baseHeight
    if (baseHeight && !baseWidth) {
      width = height * aspectRatio
    } else if (!baseHeight && baseWidth) {
      height = baseWidth / aspectRatio
    }
  }
  return { width, height }
}

export const Image: React.FC<ImageProps> = ({
  isLoading,
  src,
  aspectRatio = ASPECT_RATIO.PROFILE,
  baseWidth = IMAGE_BASE_WIDTH.PX_400,
  baseHeight,
  layout = IMAGE_LAYOUT.INTRINSIC,
  loadingPolicy = LOADING_POLICY.LAZY,
  requestWidth,
  sizes,
  isDisabled = false,
  ...rest
}) => {
  const placeholderSrc =
    PLACEHOLDER[aspectRatio] ?? PLACEHOLDER[ASPECT_RATIO.PROFILE]
  const [imgSrc, setImgSrc] = useState(src || placeholderSrc)
  const { width, height } = getResolution({
    aspectRatio,
    baseWidth,
    baseHeight,
    layout,
  })

  const computedSizes =
    sizes || ((requestWidth || width) && `${requestWidth || width}px`)
  const isPlaceholder = imgSrc === placeholderSrc
  return isLoading ? (
    <SkeletonImage
      width={width}
      height={height}
      layout={layout}
      data-test-id={DATA_TEST_ID.SKELETON_IMAGE}
    />
  ) : (
    <StyledNextImage
      unoptimized={isPlaceholder}
      placeholder={isPlaceholder ? 'empty' : 'blur'}
      src={imgSrc}
      width={width}
      height={height}
      sizes={computedSizes}
      loading={
        process.env.NEXT_PUBLIC_NODE_ENV === 'test'
          ? LOADING_POLICY.EAGER
          : loadingPolicy
      } // disable lazy loading for tests because of blurry images in percy snapshots
      loader={(props) =>
        imageLoader({
          ...props,
          placeholderSrc,
          baseWidth: requestWidth || width,
          layout,
        })
      }
      blurDataURL={
        src ? appendWidthToUrl(src, BLUR_IMAGE_WIDTH) : placeholderSrc
      }
      layout={layout}
      onError={() => {
        setImgSrc(placeholderSrc)
      }}
      isDisabled={isDisabled}
      {...rest}
    />
  )
}
