import React from 'react'
import styled from 'styled-components'
import { Image } from 'components/Image'
import { Box, Flex } from 'components/Layout'
import Link from 'components/Link'
import { Text } from 'components/Typography'
import { ASPECT_RATIO } from 'components/UploadImage/constants'
import { SCHEMA_PROPS, SCHEMA_TYPES } from 'constants/common'
import { COLOR_INTENT, SPACE } from 'Theme'

const Wrapper = styled(Flex)`
  img {
    transition: transform 300ms cubic-bezier(0.23, 0.82, 0.49, 0.79);
  }
  &:hover {
    span {
      color: ${COLOR_INTENT.HEADER.ITEM_WITH_IMAGE_TEXT.HOVER};
    }
    img {
      transform: scale(1.15);
    }
  }
`

interface ItemWithImageProps {
  title: string
  href: string
  src: string
  alt: string
  name: string
  baseWidth?: number
  baseHeight?: number
  index?: number
  onClick?: () => void
}

export const ItemWithImage: React.FC<React.PWC<ItemWithImageProps>> = ({
  title,
  src,
  alt,
  name,
  href,
  baseWidth,
  baseHeight,
  onClick,
  index = 0,
}) => (
  <Wrapper
    onClick={onClick}
    flexDirection="column"
    itemScope
    itemType={SCHEMA_TYPES.LIST_ITEM}
  >
    <Link href={href} itemProp={SCHEMA_PROPS.URL}>
      <Image
        baseWidth={baseWidth}
        baseHeight={baseHeight}
        src={src}
        alt={alt || name}
        aspectRatio={ASPECT_RATIO.HEADER_ITEM_IMAGE}
        itemProp={SCHEMA_PROPS.IMAGE}
      />
      <Box mt={SPACE.PX_8}>
        <Text
          color={COLOR_INTENT.HEADER.ITEM_WITH_IMAGE_TEXT.DEFAULT}
          itemProp={SCHEMA_PROPS.NAME}
        >
          {title}
        </Text>
      </Box>
    </Link>
    <meta itemProp={SCHEMA_PROPS.POSITION} content={String(index + 1)} />
  </Wrapper>
)
