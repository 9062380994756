import { type Hit } from 'instantsearch.js'
import React from 'react'
import { Highlight } from 'react-instantsearch'
import { DATA_TEST_CLASS } from 'shared-constants/build/testIds'
import styled from 'styled-components'
import { Image, IMAGE_LAYOUT } from 'components/Image'
import { Flex } from 'components/Layout'
import { Text } from 'components/Typography'
import {
  COLOR_INTENT,
  FONT_SIZE,
  FONT_STACK,
  FONT_WEIGHT,
  IMAGE_BASE_WIDTH,
  RADIUS,
  SPACE,
} from 'Theme'
import { INDEX_NAMES } from './utils'

const StyledOption = styled(Flex)`
  display: flex;
  align-items: center;
  border-radius: ${RADIUS.PX_6};
  cursor: pointer;
  padding: ${SPACE.PX_8} ${SPACE.PX_20};
  white-space: nowrap;
  &:hover {
    background-color: ${COLOR_INTENT.BRAND};
    .ais-Highlight__highlighted,
    .ais-Highlight__nonHighlighted,
    span {
      color: ${COLOR_INTENT.TEXT_ON_DARK};
    }
    svg {
      path {
        fill: ${COLOR_INTENT.TEXT_ON_DARK};
      }
    }
  }
  &:focus {
    background-color: ${COLOR_INTENT.BRAND};
    .ais-Highlight__highlighted,
    .ais-Highlight__nonHighlighted,
    span {
      color: ${COLOR_INTENT.TEXT_ON_DARK};
    }
    svg {
      path {
        fill: ${COLOR_INTENT.TEXT_ON_DARK};
      }
    }
  }
`

/**
 * @description Highlighting is applied reversely
 * @example Search term "a" in "abc" will highlight (turn bold) "bc"
 */
const StyledHighlight = styled(Highlight).attrs((props) => ({
  classNames: props.classNames || {
    highlighted: 'ais-Highlight__highlighted',
    nonHighlighted: 'ais-Highlight__nonHighlighted',
  },
}))`
  overflow: hidden;
  text-overflow: ellipsis;
  .ais-Highlight__highlighted {
    background-color: unset;
    font-size: ${FONT_SIZE.PX_14};
    color: ${COLOR_INTENT.GRAY_70};
  }
  .ais-Highlight__nonHighlighted {
    font-size: ${FONT_SIZE.PX_14};
    color: ${COLOR_INTENT.GRAY_70};
    font-weight: ${FONT_WEIGHT.SEMI_BOLD};
  }
`

const ExpertImage = styled(Image)`
  border-radius: ${RADIUS.CIRCLE};
`

const ProductImage = styled(Image)`
  border-radius: ${RADIUS.PX_4};
`

const isProduction = process.env.NODE_ENV === 'production'

const OPTION_DATA_TEST_CLASSES = {
  [INDEX_NAMES.EXPERTS]: isProduction
    ? ''
    : DATA_TEST_CLASS.EXPERT_SEARCH_OPTION,
  [INDEX_NAMES.PRODUCT_CATEGORIES]: isProduction
    ? ''
    : DATA_TEST_CLASS.PRODUCT_CATEGORY_SEARCH_OPTION,
  [INDEX_NAMES.BRANDS]: isProduction ? '' : DATA_TEST_CLASS.BRAND_SEARCH_OPTION,
  [INDEX_NAMES.PRODUCTS]: isProduction
    ? ''
    : DATA_TEST_CLASS.PRODUCT_SEARCH_OPTION,
}

interface Props {
  hit: Hit
  indexName: (typeof INDEX_NAMES)[keyof typeof INDEX_NAMES]
  onClick: () => void
  tabIndex: number
}

export const SearchResultDropdownOption: React.FC<React.PWC<Props>> = ({
  hit,
  indexName,
  onClick,
  tabIndex,
}) => (
  <StyledOption
    onClick={onClick}
    data-test-class={OPTION_DATA_TEST_CLASSES[indexName]}
    gap={SPACE.PX_8}
    className="search-result-item"
    tabIndex={tabIndex}
  >
    {indexName === INDEX_NAMES.EXPERTS && (
      <Flex
        maxHeight={IMAGE_BASE_WIDTH.PX_30}
        maxWidth={IMAGE_BASE_WIDTH.PX_30}
      >
        <ExpertImage
          baseWidth={IMAGE_BASE_WIDTH.PX_30}
          baseHeight={IMAGE_BASE_WIDTH.PX_30}
          layout={IMAGE_LAYOUT.FIXED}
          alt={`${hit.title}'s profile picture`}
          src={hit.imageUrl}
        />
      </Flex>
    )}
    {indexName === INDEX_NAMES.PRODUCTS && (
      <Flex
        maxHeight={IMAGE_BASE_WIDTH.PX_30}
        maxWidth={IMAGE_BASE_WIDTH.PX_30}
      >
        <ProductImage
          baseWidth={IMAGE_BASE_WIDTH.PX_30}
          baseHeight={IMAGE_BASE_WIDTH.PX_30}
          layout={IMAGE_LAYOUT.FIXED}
          alt={`${hit.title}'s product picture`}
          src={hit.imageUrl}
        />
      </Flex>
    )}
    {indexName === INDEX_NAMES.EXPERTS ? (
      <Flex flexDirection="column" width="80%" overflow="hidden">
        <StyledHighlight attribute="title" hit={hit} />
        <Text
          isEllipsis
          color={COLOR_INTENT.SEARCH.JOB_TITLE}
          fontFamily={FONT_STACK.SERIF}
          fontSize={FONT_SIZE.PX_12}
        >
          {hit.subtitle ?? ''}
        </Text>
      </Flex>
    ) : (
      <StyledHighlight attribute="title" hit={hit} />
    )}
  </StyledOption>
)
