import React from 'react'
import { Flex } from 'components/Layout'
import { Text } from 'components/Typography'
import { COLOR_INTENT, FONT_SIZE } from 'Theme'

export const Copyright = () => (
  <Flex
    width={{ MOBILE: '100%', TABLET: 'fit-content' }}
    justifyContent={{ MOBILE: 'center', TABLET: 'flex-start' }}
  >
    <Text
      color={COLOR_INTENT.FOOTER.COPYRIGHT.TEXT}
      fontSize={{
        MOBILE: FONT_SIZE.PX_14,
        TABLET: FONT_SIZE.PX_12,
        DESKTOP: FONT_SIZE.PX_14,
      }}
      whiteSpace="nowrap"
    >
      &copy; {new Date().getFullYear()} The Expert Inc.
    </Text>
  </Flex>
)
