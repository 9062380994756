import React from 'react'
import { Image as ImageWithoutKey } from './Image'

interface ImageProps extends React.ComponentProps<typeof ImageWithoutKey> {
  src?: string
}

export const Image: React.FC<ImageProps> = ({ src, ...rest }) => (
  <ImageWithoutKey src={src} key={src} {...rest} />
)
