import { rem } from 'polished'
import { SPACE } from 'Theme'

export const LOGO_MOBILE_DIMENSIONS = { width: rem(130), height: rem(60) }

export const MOBILE_MENU_MARGIN = { TABLET: SPACE.PX_20, DESKTOP: SPACE.PX_40 }

export type HeaderType = (typeof HEADER_TYPE)[keyof typeof HEADER_TYPE]
export const HEADER_TYPE = {
  DEFAULT: 'DEFAULT',
  CHECKOUT: 'CHECKOUT',
} as const

export const ANIMATION_VARIANT = {
  OPEN: 'OPEN',
  CLOSE: 'CLOSE',
}

export const DESKTOP_ANIMATION_DELAY = 0.2
