import { rem, rgba } from 'polished'
import React from 'react'
import {
  type ColorProps,
  type SpaceProps,
  type WidthProps,
} from 'styled-system'
import { Box } from 'components/Layout'
import { COLOR_INTENT } from 'Theme'

const DIVIDER_HEIGHT = rem(1)

interface DividerProps
  extends SpaceProps,
    WidthProps,
    Pick<ColorProps, 'color'> {
  opacity?: number
}

export const Divider: React.FC<React.PWC<DividerProps>> = ({
  color = COLOR_INTENT.DIVIDER.DEFAULT_BACKGROUD,
  opacity = 0.2,
  ...props
}) => (
  <Box
    width="100%"
    height={DIVIDER_HEIGHT}
    bg={rgba(color as string, opacity)}
    {...props}
  />
)
