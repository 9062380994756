import { motion } from 'framer-motion'
import styled from 'styled-components'
import { SIGNED_IN_AS_USER_HEADER_DESKTOP_HEIGHT } from 'components/SignedInAsUserHeader'
import { HEADER_HEIGHT_DESKTOP, TRADE_RIBBON_HEIGHT } from 'constants/common'
import { COLOR_INTENT, Z_INDEX } from 'Theme'

export const DROPDOWN_MENU_OVERLAY_TRANSITION_DURATION_IN_S = 0.6

type StylingProps = {
  isTradeProgramRibbonHidden?: boolean
  isUserImpersonifiedAdmin?: boolean
}

const getDropdownHeight = ({
  isTradeProgramRibbonHidden,
  isUserImpersonifiedAdmin,
}: StylingProps) => {
  if (!isTradeProgramRibbonHidden && isUserImpersonifiedAdmin) {
    return `calc(100vh - calc(${HEADER_HEIGHT_DESKTOP} + ${TRADE_RIBBON_HEIGHT} + ${SIGNED_IN_AS_USER_HEADER_DESKTOP_HEIGHT}))`
  }

  if (!isTradeProgramRibbonHidden) {
    return `calc(100vh - calc(${HEADER_HEIGHT_DESKTOP} + ${TRADE_RIBBON_HEIGHT}))`
  }

  if (isUserImpersonifiedAdmin) {
    return `calc(100vh - calc(${HEADER_HEIGHT_DESKTOP} + ${SIGNED_IN_AS_USER_HEADER_DESKTOP_HEIGHT}))`
  }

  return `calc(100vh - ${HEADER_HEIGHT_DESKTOP})`
}

const getDropdownTop = ({
  isTradeProgramRibbonHidden,
  isUserImpersonifiedAdmin,
}: StylingProps) => {
  if (!isTradeProgramRibbonHidden && isUserImpersonifiedAdmin) {
    return `calc(${HEADER_HEIGHT_DESKTOP} + ${TRADE_RIBBON_HEIGHT} + ${SIGNED_IN_AS_USER_HEADER_DESKTOP_HEIGHT})`
  }

  if (!isTradeProgramRibbonHidden) {
    return `calc(${HEADER_HEIGHT_DESKTOP} + ${TRADE_RIBBON_HEIGHT})`
  }

  if (isUserImpersonifiedAdmin) {
    return `calc(${HEADER_HEIGHT_DESKTOP} + ${SIGNED_IN_AS_USER_HEADER_DESKTOP_HEIGHT})`
  }

  return HEADER_HEIGHT_DESKTOP
}

export const DropdownMenuOverlay = styled(motion.div).withConfig({
  shouldForwardProp: (prop) =>
    !['isTradeProgramRibbonHidden', 'isUserImpersonifiedAdmin'].includes(prop),
})`
  position: fixed;
  height: ${({
    isTradeProgramRibbonHidden = false,
    isUserImpersonifiedAdmin = false,
  }) =>
    getDropdownHeight({
      isTradeProgramRibbonHidden,
      isUserImpersonifiedAdmin,
    })};
  width: 100vw;
  z-index: ${Z_INDEX.NOT_SHOWN};
  left: 0;
  background-color: ${COLOR_INTENT.HEADER.OVERLAY};
  top: ${({
    isTradeProgramRibbonHidden = false,
    isUserImpersonifiedAdmin = false,
  }) =>
    getDropdownTop({
      isTradeProgramRibbonHidden,
      isUserImpersonifiedAdmin,
    })};
`
