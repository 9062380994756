import styled from 'styled-components'
import {
  type LayoutProps,
  type OrderProps,
  type PositionProps,
  type SpaceProps,
} from 'styled-system'
import { Box } from 'components/Layout'
import { SPACE } from 'Theme'

interface MenuIconWrapperProps
  extends LayoutProps,
    OrderProps,
    PositionProps,
    SpaceProps {
  isMobile: boolean
}

export const MenuIconWrapper = styled(Box)<MenuIconWrapperProps>`
  user-select: none;
  &:hover {
    cursor: pointer;
  }
  padding: ${({ isMobile }) => (isMobile ? SPACE.PX_10 : SPACE.PX_6)};
`
