export const INDEX_NAMES = {
  PRODUCTS: 'Products',
  EXPERTS: 'Experts',
  BRANDS: 'Brands',
  PRODUCT_CATEGORIES: 'Product Categories',
} as const

export const DROPDOWN_MAX_RESULTS = 10

/**
 * @description Weights for each index when 2-3 characters are entered in the search bar
 * - Total weight should be 1 (100%) -> meaning that if we have 10 results to display
 */
export const VAGUE_SEARCH_INDICES_WEIGHTS = {
  [INDEX_NAMES.EXPERTS]: 0.4,
  [INDEX_NAMES.PRODUCT_CATEGORIES]: 0.2,
  [INDEX_NAMES.BRANDS]: 0.2,
  [INDEX_NAMES.PRODUCTS]: 0,
} as const

/**
 * @description Weights for each index when 4+ characters are entered in the search bar
 * - Total weight should be 1 (100%) -> meaning that if we have 10 results to display
 */
export const INDICES_WEIGHTS = {
  [INDEX_NAMES.EXPERTS]: 0.3,
  [INDEX_NAMES.PRODUCT_CATEGORIES]: 0.2,
  [INDEX_NAMES.BRANDS]: 0.2,
  [INDEX_NAMES.PRODUCTS]: 0.1,
} as const

/**
 * The delay in milliseconds for debounce serach functionality.
 */
export const DEBOUNCE_DELAY = 500

/**
 * The minimum length required for a search query.
 */
export const MIN_SEARCH_LENGTH = 2
