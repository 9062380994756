import type { SearchClient } from 'algoliasearch'
import type { Hit } from 'instantsearch.js'
import React, { useEffect, useMemo, useRef } from 'react'
import { Configure, Index, InstantSearch } from 'react-instantsearch'
import { Flex } from 'components/Layout'
import { algoliaClient as defaultAlgoliaClient } from 'utils/algolia'
import { SearchInput } from './SearchInput'
import { SearchResultsDropdown } from './SearchResultsDropdown'
import { INDEX_NAMES } from './utils'

interface Props {
  searchString: string
  setSearchString: React.Dispatch<React.SetStateAction<string>>
  isDropdownVisible: boolean
  setIsDropdownVisible: React.Dispatch<React.SetStateAction<boolean>>
  onOptionSelect: (hit: Hit) => void
  onNewSearch: (query: string) => void
  isSearchInputFocused: boolean
  algoliaClient?: SearchClient
  onInputFocus?: () => void
  onInputBlur?: () => void
}

export const Search = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      searchString,
      setSearchString,
      isDropdownVisible,
      setIsDropdownVisible,
      onOptionSelect,
      onNewSearch,
      isSearchInputFocused,
      algoliaClient = defaultAlgoliaClient,
      onInputFocus,
      onInputBlur,
    },
    searchDropdownRef
  ) => {
    const searchInputRef = useRef(null)

    // https://www.algolia.com/doc/guides/building-search-ui/going-further/conditional-requests/js/
    const searchClient = useMemo(
      () => ({
        ...algoliaClient,
        search<T>(requests: Parameters<SearchClient['search']>[0]) {
          if (requests.every(({ params }) => !params.query)) {
            return Promise.resolve({
              results: requests.map(() => ({
                hits: [],
                nbHits: 0,
                nbPages: 0,
                page: 0,
                processingTimeMS: 0,
                hitsPerPage: 0,
                exhaustiveNbHits: false,
                query: '',
                params: '',
              })),
            })
          }

          return algoliaClient.search<T>(requests)
        },
      }),
      [algoliaClient]
    )
    useEffect(() => {
      const handleEscapeKey = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
          const inputElement = searchInputRef.current.querySelector('input')
          if (isDropdownVisible) {
            setIsDropdownVisible(false)
          }
          if (inputElement === document.activeElement) {
            inputElement.blur()
          }
        }
      }
      document.addEventListener('keydown', handleEscapeKey)

      return () => {
        document.removeEventListener('keydown', handleEscapeKey)
      }
    }, [isDropdownVisible, searchInputRef, setIsDropdownVisible])

    return (
      <InstantSearch
        searchClient={searchClient}
        indexName={INDEX_NAMES.EXPERTS}
        insights
        future={{
          preserveSharedStateOnUnmount: false,
        }}
      >
        <Flex
          width="100%"
          flexDirection="column"
          position={{ MOBILE: 'initial', TABLET: 'relative' }}
          onTouchStart={(event) => {
            if (searchInputRef.current !== event.target) {
              searchInputRef.current.querySelector('input').blur()
            }
          }}
        >
          <SearchInput
            ref={searchInputRef}
            searchString={searchString}
            setSearchString={setSearchString}
            setIsDropdownVisible={setIsDropdownVisible}
            onFocus={onInputFocus}
            onBlur={onInputBlur}
          />
          <SearchResultsDropdown
            ref={searchDropdownRef}
            isVisible={isDropdownVisible}
            onSelect={onOptionSelect}
            onNewSearch={onNewSearch}
            searchInputRef={searchInputRef}
            isSearchInputFocused={isSearchInputFocused}
          />
          <Configure hitsPerPage={10} filters="isSearchable:true" />
          <Index indexName={INDEX_NAMES.PRODUCT_CATEGORIES} />
          <Index indexName={INDEX_NAMES.BRANDS} />
          <Index indexName={INDEX_NAMES.PRODUCTS} />
        </Flex>
      </InstantSearch>
    )
  }
)
