import { AnimatePresence, motion } from 'framer-motion'
import React, { Fragment } from 'react'
import styled from 'styled-components'
import { Flex } from 'components/Layout'
import { LinkWithText, type LinkWithTextProps } from 'components/Link'
import { H5 } from 'components/Typography'
import { HEADER_HEIGHT_MOBILE_PARTIAL } from 'constants/common'
import { ROUTE } from 'constants/routes'
import {
  BORDER_WIDTH,
  COLOR_INTENT,
  FONT_SIZE,
  FONT_STACK,
  MENU_CUBIC_BEZIER_EASE,
  SPACE,
} from 'Theme'
import { type NavigationItem } from 'types/graphql-generated'
import { ANIMATION_VARIANT } from '../constants'
import { MobileSubMenuItem } from './MobileSubMenuItem'

const DROP_DOWN_VARIANTS = {
  [ANIMATION_VARIANT.OPEN]: {
    height: `calc(100vh - ${HEADER_HEIGHT_MOBILE_PARTIAL})`,
  },
  [ANIMATION_VARIANT.CLOSE]: {
    height: 0,
  },
}

const MENU_DROPDOWN_HEIGHT_ANIMATION_DURATION_IN_S = 0.4

const Dropdown = styled(motion.div)`
  display: flex;
  background-color: ${COLOR_INTENT.MENU.BACKGROUND};
  overflow: hidden;
  align-items: center;
  flex-direction: column;
  position: absolute;
  width: 100vw;
  left: 0;
  top: ${HEADER_HEIGHT_MOBILE_PARTIAL};
  border-top: ${BORDER_WIDTH.PX_1} solid
    ${({ isBorderVisible }) =>
      isBorderVisible ? COLOR_INTENT.HEADER.BORDER : 'transparent'};
`

const MenuLink: React.FC<React.PWC<LinkWithTextProps>> = ({
  children,
  href,
  onClick,
}) => (
  <LinkWithText
    fontSize={FONT_SIZE.PX_16}
    href={href}
    color={COLOR_INTENT.HEADER.ITEM_WITH_IMAGE_TEXT.DEFAULT}
    onClick={onClick}
  >
    {children}
  </LinkWithText>
)

interface MobileMenuProps {
  navigationItems: Array<NavigationItem>
  isMenuOpen: boolean
  onMenuClose: () => void
  isBorderVisible: boolean
}

export const MobileMenu: React.FC<React.PWC<MobileMenuProps>> = ({
  isMenuOpen,
  navigationItems,
  onMenuClose,
  isBorderVisible,
}) => (
  <AnimatePresence mode="wait">
    {isMenuOpen && (
      <Dropdown
        key="dropdown"
        isBorderVisible={isBorderVisible}
        variants={DROP_DOWN_VARIANTS}
        initial={ANIMATION_VARIANT.CLOSE}
        animate={ANIMATION_VARIANT.OPEN}
        exit={ANIMATION_VARIANT.CLOSE}
        transition={{
          ...MENU_CUBIC_BEZIER_EASE,
          duration: MENU_DROPDOWN_HEIGHT_ANIMATION_DURATION_IN_S,
        }}
      >
        <Flex
          width="100%"
          flexDirection="column"
          alignItems="center"
          pb={SPACE.PX_120}
          overflowY="scroll"
        >
          <H5 pt={SPACE.PX_24} mb={SPACE.PX_21} fontFamily={FONT_STACK.SERIF}>
            Get advice
          </H5>
          <Flex mb={SPACE.PX_22} alignItems="center">
            <MenuLink href={ROUTE.CONSULTATIONS()} onClick={onMenuClose}>
              Consultations
            </MenuLink>
          </Flex>
          <Flex mb={SPACE.PX_22} alignItems="center">
            <MenuLink href={ROUTE.BLOG_LIST()} onClick={onMenuClose}>
              Stories
            </MenuLink>
          </Flex>
          {navigationItems.map(({ id, title: heading, children }) => (
            <Fragment key={id}>
              <H5
                textAlign="center"
                mt={SPACE.PX_16}
                mb={SPACE.PX_21}
                fontFamily={FONT_STACK.SERIF}
              >
                {heading}
                <br />
              </H5>
              {children.map((navigationItem) => {
                const {
                  id: subItemId,
                  title,
                  cmsPage: { href },
                } = navigationItem
                const hasSubMenu = navigationItem.children.length > 0
                if (hasSubMenu) {
                  return (
                    <MobileSubMenuItem
                      key={subItemId}
                      title={title}
                      navigationItem={navigationItem}
                      onMenuClose={onMenuClose}
                    />
                  )
                }
                return (
                  <Flex key={subItemId} mb={SPACE.PX_20} alignItems="center">
                    <MenuLink onClick={onMenuClose} href={href}>
                      {title}
                    </MenuLink>
                  </Flex>
                )
              })}
            </Fragment>
          ))}
        </Flex>
      </Dropdown>
    )}
  </AnimatePresence>
)
