import { useMutation, gql } from '@apollo/client'
import React from 'react'
import { DATA_TEST_ID } from 'shared-constants/build/testIds'
import styled from 'styled-components'
import { Callout, CALLOUT_INTENT, CALLOUT_VARIANT } from 'components/Callout'
import Icon from 'components/Icon'
import { Toast } from 'components/Toast'
import { Text } from 'components/Typography'
import { COLOR, FONT_SIZE, FONT_WEIGHT } from 'Theme'
import { useMedia } from 'useMedia'

const StyledText = styled(Text)`
  cursor: pointer;
`

const RESEND_ACTIVATION_EMAIL_MUTATION = gql`
  mutation resendActivationEmail {
    resendActivationEmail
  }
`

const NotVerifiedEmailHeader = () => {
  const media = useMedia()
  const [executeResendActivationEmail] = useMutation(
    RESEND_ACTIVATION_EMAIL_MUTATION
  )

  const mobileFontSize = {
    ...(media.MOBILE ? { fontSize: FONT_SIZE.PX_12 } : {}),
  }

  return (
    <Callout
      icon={Icon.Warning}
      intent={CALLOUT_INTENT.DANGER}
      variant={CALLOUT_VARIANT.MINIMAL}
    >
      <Text {...mobileFontSize} color={COLOR.SECONDARY_2}>
        {`It looks like you still haven't verified your email address. Please check your email and confirm your address. If you couldn't find the confirmation email, `}
        <StyledText
          {...mobileFontSize}
          onClick={async () => {
            const response = await executeResendActivationEmail()
            if (!response.errors) {
              Toast.success('Verification email send')
            }
          }}
          color={COLOR.SECONDARY_1}
          fontWeight={FONT_WEIGHT.MEDIUM}
          data-test-id={DATA_TEST_ID.RESEND_VERTIFICATION_EMAIL}
        >
          click here to resend the verification email
        </StyledText>
        .
      </Text>
    </Callout>
  )
}

export default NotVerifiedEmailHeader
