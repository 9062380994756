import React from 'react'
import { DATA_TEST_ID } from 'shared-constants/build/testIds'
import { Box, Flex } from 'components/Layout'
import Link from 'components/Link'
import { HeaderLogo } from 'components/Logo'
import { ROUTE } from 'constants/routes'
import { type NavigationItem } from 'types/graphql-generated'
import { MOBILE_MENU_MARGIN } from '../constants'
import { DesktopNavItem } from './DesktopNavItem'

interface DesktopNavigationProps {
  navigationItems: Array<NavigationItem>
  searchComponent: React.ReactNode
  iconsMenu: React.ReactNode
  headerLogoTitleId: string
}

export const DesktopNavigation: React.FC<React.PWC<DesktopNavigationProps>> = ({
  navigationItems,
  searchComponent,
  iconsMenu,
  headerLogoTitleId,
}) => (
  <Flex
    width="100%"
    height="100%"
    alignItems="center"
    data-test-id={DATA_TEST_ID.DESKTOP_NAVIGATION}
  >
    <Box>
      <Link href={ROUTE.FRONT_PAGE()} aria-label="home">
        <HeaderLogo titleId={headerLogoTitleId} />
      </Link>
    </Box>
    <Flex height="100%" mx={MOBILE_MENU_MARGIN}>
      <DesktopNavItem title="Consultations" href={ROUTE.CONSULTATIONS()} />
      {navigationItems.map(({ id, title, cmsPage: { href }, children }) => (
        <DesktopNavItem
          key={id}
          title={title}
          href={title === 'Shop' ? ROUTE.SHOWROOM() : href}
          dropDownMenu={children}
        />
      ))}
      <DesktopNavItem title="Stories" href={ROUTE.BLOG_LIST()} />
    </Flex>
    <Box width="100%">{searchComponent}</Box>
    {iconsMenu}
  </Flex>
)
