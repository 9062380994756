import { AnimatePresence, motion } from 'framer-motion'
import React, { useState } from 'react'
import styled from 'styled-components'
import { space } from 'styled-system'
import Icon from 'components/Icon'
import { Flex, Grid } from 'components/Layout'
import Link, { LinkWithText } from 'components/Link'
import { H5, Text } from 'components/Typography'
import { SCHEMA_PROPS, SCHEMA_TYPES } from 'constants/common'
import {
  COLOR_INTENT,
  FONT_SIZE,
  FONT_STACK,
  SPACE,
  SUBMENU_CUBIC_BEZIER_EASE,
} from 'Theme'
import { type NavigationItem } from 'types/graphql-generated'
import { ANIMATION_VARIANT } from '../constants'
import { ItemWithImage } from './ItemWithImage'

const SUB_MENU_VARIANTS = {
  [ANIMATION_VARIANT.OPEN]: {
    translateX: '0vw',
  },
  [ANIMATION_VARIANT.CLOSE]: {
    translateX: '100vw',
    onTransitionEnd: {
      display: 'none',
    },
  },
}

const SUB_MENU_TRANSITION_DURATION_IN_S = 0.3

const SubMenu = styled(motion.div)`
  background-color: ${COLOR_INTENT.MENU.BACKGROUND};
  width: 100vw;
  height: 100%;
  position: absolute;
  overflow-y: auto;
  ${space}
`

const SubMenuParent = styled(Flex)`
  cursor: pointer;
`

const CenteredH5 = styled(H5)`
  width: 100%;
`

interface HeadingWithArrowProps {
  setIsSubMenuShown: (value: boolean) => void
  title: string
  itemProp?: string
}

const HeadingWithArrow: React.FC<React.PWC<HeadingWithArrowProps>> = ({
  setIsSubMenuShown,
  title,
  itemProp,
}) => (
  <Flex width="100%" mb={SPACE.PX_21}>
    <Flex
      flex="1"
      onClick={() => {
        setIsSubMenuShown(false)
      }}
    >
      <Icon.MenuArrowLeft
        color={COLOR_INTENT.HEADER.ITEM_WITH_IMAGE_TEXT.DEFAULT}
      />
    </Flex>
    <Flex flex="1 1 auto">
      <CenteredH5
        textAlign="center"
        fontFamily={FONT_STACK.SERIF}
        m="0"
        itemProp={itemProp}
      >
        {title}
      </CenteredH5>
    </Flex>
    <Flex flex="1" />
  </Flex>
)

interface MobileSubMenuItemProps {
  title: string
  navigationItem: NavigationItem
  onMenuClose: () => void
}

export const MobileSubMenuItem: React.FC<React.PWC<MobileSubMenuItemProps>> = ({
  title,
  navigationItem,
  onMenuClose,
}) => {
  const [isSubMenuShown, setIsSubMenuShown] = useState(false)
  const subMenuRef = React.createRef<HTMLDivElement>()

  return (
    <AnimatePresence>
      <SubMenuParent
        onClick={() => {
          setIsSubMenuShown(true)
        }}
        mb={SPACE.PX_20}
        alignItems="center"
      >
        <Text
          fontSize={FONT_SIZE.PX_16}
          color={COLOR_INTENT.HEADER.ITEM_WITH_IMAGE_TEXT.DEFAULT}
        >
          {title}
        </Text>
        <Icon.MenuArrowRight
          color={COLOR_INTENT.HEADER.ITEM_WITH_IMAGE_TEXT.DEFAULT}
        />
      </SubMenuParent>
      <SubMenu
        key="submenu"
        variants={SUB_MENU_VARIANTS}
        initial={ANIMATION_VARIANT.CLOSE}
        animate={
          isSubMenuShown ? ANIMATION_VARIANT.OPEN : ANIMATION_VARIANT.CLOSE
        }
        exit={ANIMATION_VARIANT.CLOSE}
        transition={{
          ...SUBMENU_CUBIC_BEZIER_EASE,
          duration: SUB_MENU_TRANSITION_DURATION_IN_S,
        }}
        pb={SPACE.PX_80}
        px={SPACE.PX_16}
      >
        <Flex
          pt={SPACE.PX_16}
          pb={SPACE.PX_80}
          height="100%"
          width="100%"
          flexDirection="column"
          alignItems="center"
          mx="auto"
          ref={subMenuRef}
          itemScope
          itemType={SCHEMA_TYPES.THING}
        >
          <HeadingWithArrow
            setIsSubMenuShown={setIsSubMenuShown}
            title={navigationItem.title}
            itemProp={SCHEMA_PROPS.NAME}
          />
          {navigationItem.subItemsWithImages ? (
            <Grid
              gridRowGap={SPACE.PX_16}
              gridColumnGap={SPACE.PX_24}
              gridTemplateColumns="1fr 1fr"
              itemScope
              itemType={SCHEMA_TYPES.ITEM_LIST}
            >
              {navigationItem.children.map(
                (
                  {
                    id,
                    attachment: { url, alt, name },
                    cmsPage: { href },
                    title: itemTitle,
                  },
                  index
                ) => (
                  <ItemWithImage
                    key={id}
                    onClick={onMenuClose}
                    src={url}
                    alt={alt}
                    name={name}
                    href={href}
                    title={itemTitle}
                    index={index}
                  />
                )
              )}
            </Grid>
          ) : (
            navigationItem.children.map(
              ({ id, title: itemTitle, cmsPage: { href: itemHref } }) => (
                <Flex
                  key={id}
                  onClick={onMenuClose}
                  mb={SPACE.PX_20}
                  alignItems="center"
                >
                  <LinkWithText
                    href={itemHref}
                    fontSize={FONT_SIZE.PX_16}
                    color={COLOR_INTENT.HEADER.ITEM_WITH_IMAGE_TEXT.DEFAULT}
                  >
                    {itemTitle}
                  </LinkWithText>
                </Flex>
              )
            )
          )}
          <Flex
            alignItems="center"
            {...(navigationItem.subItemsWithImages ? { py: SPACE.PX_32 } : {})}
          >
            <Link
              href={navigationItem.cmsPage.href}
              aria-label={
                navigationItem.subItemsWithImages ? 'View more' : 'Shop all'
              }
            >
              <Text
                fontSize={FONT_SIZE.PX_16}
                color={COLOR_INTENT.HEADER.ITEM_WITH_IMAGE_TEXT.DEFAULT}
                onClick={onMenuClose}
              >
                {navigationItem.subItemsWithImages ? 'View more' : 'Shop all'}
              </Text>
            </Link>
          </Flex>
        </Flex>
      </SubMenu>
    </AnimatePresence>
  )
}
