import type { Hit } from 'instantsearch.js'
import connectAutocomplete, {
  type AutocompleteConnectorParams,
  type AutocompleteWidgetDescription,
} from 'instantsearch.js/es/connectors/autocomplete/connectAutocomplete'
import { flatMap } from 'lodash'
import { rem } from 'polished'
import React, { useEffect, useMemo, useState } from 'react'
import { useConnector, useInstantSearch } from 'react-instantsearch'
import { DATA_TEST_ID } from 'shared-constants/build/testIds'
import styled from 'styled-components'
import Icon, { ICON_SIZE } from 'components/Icon'
import { Box, Flex } from 'components/Layout'
import { Loader } from 'components/Loader'
import { Text } from 'components/Typography'
import {
  HEADER_HEIGHT_MOBILE_PARTIAL,
  HEADER_HEIGHT_MOBILE_FULL,
} from 'constants/common'
import {
  SPACE,
  COLOR_INTENT,
  FONT_SIZE,
  RADIUS,
  INPUT_SIZE,
  mq,
  Z_INDEX,
} from 'Theme'
import { SearchResultDropdownOption } from './SearchResultDropdownOption'
import { getResultsToDisplay } from './utils'
import { type INDEX_NAMES } from './utils/constants'

export const SEARCH_RESULT_DROPDOWN_ID = 'search-result-dropdown'

export const useAutocomplete = (props?: AutocompleteConnectorParams) =>
  useConnector<AutocompleteConnectorParams, AutocompleteWidgetDescription>(
    connectAutocomplete,
    props
  )

const DROPDOWN_HEIGHT = {
  MOBILE_PARTIAL: `calc(100vh - ${HEADER_HEIGHT_MOBILE_PARTIAL})`,
  MOBILE_FULL: `calc(100vh - ${HEADER_HEIGHT_MOBILE_FULL})`,
  TABLET: 'initial',
}

const DROPDOWN_MAX_HEIGHT = {
  TABLET: rem(500),
  DESKTOP: rem(600),
}

const Wrapper = styled(Flex)`
  position: absolute;
  flex-direction: column;
  background-color: white;
  width: 100%;
  overflow-y: auto;
  border: 1px solid #ddd;
  z-index: ${Z_INDEX.SEARCH};
  text-overflow: ellipsis;
  height: ${({ isSearchInputFocused }) =>
    isSearchInputFocused
      ? DROPDOWN_HEIGHT.MOBILE_PARTIAL
      : DROPDOWN_HEIGHT.MOBILE_FULL};
  margin-top: unset;
  top: ${({ isSearchInputFocused }) =>
    isSearchInputFocused
      ? HEADER_HEIGHT_MOBILE_FULL
      : HEADER_HEIGHT_MOBILE_PARTIAL};
  left: 0;
  ${mq.from.TABLET`
    border-radius: ${RADIUS.PX_10};
    height: ${DROPDOWN_HEIGHT.TABLET};
    max-height: ${DROPDOWN_MAX_HEIGHT.TABLET};
    margin-top: ${SPACE.PX_6};
    top: ${INPUT_SIZE.M};
    left: initial;
  `}
  ${mq.from.DESKTOP`
    max-height: ${DROPDOWN_MAX_HEIGHT.DESKTOP};
  `}
`

const OptionTitle = styled(Text)`
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  padding: ${SPACE.PX_10} ${SPACE.PX_16};
  span {
    z-index: ${Z_INDEX.SEARCH_DROPDOWN_OPTION_TITLE};
    font-size: ${FONT_SIZE.PX_12};
    color: ${COLOR_INTENT.GRAY_70};
    background: #fff;
    padding: 0 ${SPACE.PX_12} 0 0;
  }
`

const Divider = styled(Box)`
  height: 1px;
  width: 100%;
  background-color: ${COLOR_INTENT.GRAY_10};
`

interface Props extends AutocompleteConnectorParams {
  isVisible: boolean
  onSelect: (hit: Hit) => void
  onNewSearch: (query: string) => void
  searchInputRef: React.MutableRefObject<HTMLInputElement>
  isSearchInputFocused: boolean
}

export const SearchResultsDropdown = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      isVisible,
      onSelect,
      onNewSearch,
      escapeHTML,
      searchInputRef,
      isSearchInputFocused,
    },
    ref
  ) => {
    const { indices, currentRefinement } = useAutocomplete({ escapeHTML })
    const { status } = useInstantSearch()
    const [focusedIndex, setFocusedIndex] = useState(null)

    const results = useMemo(
      () => getResultsToDisplay(indices, currentRefinement),
      [indices, currentRefinement]
    )
    const hits = useMemo(
      () => flatMap(results?.map((result) => result.hits)),
      [results]
    )

    useEffect(() => {
      const handleKeyDown = (event) => {
        if (typeof ref === 'function') {
          return
        }
        if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
          searchInputRef.current.focus()
          setFocusedIndex(null)
        }
        if (event.key === 'ArrowDown') {
          event.preventDefault()
          const resultItems = Array.from(
            ref.current?.querySelectorAll('.search-result-item') ?? []
          ) as HTMLDivElement[]

          if (!resultItems?.length) {
            return
          }
          // Calculate the index of the next item to focus
          const nextIndex =
            focusedIndex === null
              ? 0
              : Math.min(focusedIndex + 1, resultItems.length - 1)
          resultItems[nextIndex].focus()
          // Update the focused index in the state
          setFocusedIndex(nextIndex)
        }
        if (event.key === 'ArrowUp') {
          // Prevent the default behavior of the arrow key
          event.preventDefault()
          // Get all result items by class
          const resultItems = Array.from(
            ref.current?.querySelectorAll('.search-result-item') ?? []
          ) as HTMLDivElement[]

          if (!resultItems?.length) {
            return
          }
          // Calculate the index of the previous item to focus
          const prevIndex =
            focusedIndex === null
              ? resultItems.length - 1
              : Math.max(focusedIndex - 1, 0)
          resultItems[prevIndex].focus()
          setFocusedIndex(prevIndex)
        } else if (event.key === 'Enter') {
          // Call the action on the focused item
          if (focusedIndex !== null) {
            onSelect(hits[focusedIndex])
          }
        }
      }
      // Add event listener when the component mounts
      document.addEventListener('keydown', handleKeyDown)
      // Clean up the event listener when the component unmounts
      return () => {
        document.removeEventListener('keydown', handleKeyDown)
      }
    }, [focusedIndex, ref, hits, searchInputRef, onSelect])

    useEffect(() => {
      setFocusedIndex(null)
      if (status !== 'loading') {
        return
      }
      onNewSearch(currentRefinement)
    }, [currentRefinement, status, onNewSearch, setFocusedIndex])

    if (!isVisible || !results) {
      return null
    }

    if (status === 'error') {
      return (
        <Wrapper
          ref={ref}
          p={{
            MOBILE: `0 ${SPACE.PX_15}`,
            TABLET: `${SPACE.PX_40} ${SPACE.PX_80}`,
          }}
          alignItems="center"
          justifyContent="center"
          id={SEARCH_RESULT_DROPDOWN_ID}
          role="listbox"
          data-test-id={DATA_TEST_ID.SEARCH_RESULT_DROPDOWN}
          isSearchInputFocused={isSearchInputFocused}
          body-scroll-lock-ignore
        >
          <Box mb={SPACE.PX_15}>
            <Icon.MenuSearch size={ICON_SIZE.PX_20} />
          </Box>
          <Text
            color={COLOR_INTENT.SEARCH.NO_RESULT_TEXT}
            fontSize={FONT_SIZE.PX_14}
            textAlign="center"
            mb={SPACE.PX_10}
          >
            Something went wrong.
          </Text>
          <Text
            textAlign="center"
            color={COLOR_INTENT.SEARCH.NO_RESULT_SUB_TEXT}
            fontSize={FONT_SIZE.PX_12}
          >
            Please, try searching again.
          </Text>
        </Wrapper>
      )
    }

    if (status !== 'idle') {
      return (
        <Wrapper
          ref={ref}
          p={{
            MOBILE: `0 ${SPACE.PX_15}`,
            TABLET: `${SPACE.PX_10} ${SPACE.PX_12}`,
          }}
          alignItems="center"
          justifyContent="center"
          role="listbox"
          id={SEARCH_RESULT_DROPDOWN_ID}
          data-test-id={DATA_TEST_ID.SEARCH_RESULT_DROPDOWN}
          isSearchInputFocused={isSearchInputFocused}
          body-scroll-lock-ignore
        >
          <Loader data-test-id={DATA_TEST_ID.LOADER} />
        </Wrapper>
      )
    }

    const hasHits = results.some((index) => index.hits.length > 0)

    if (!hasHits) {
      return (
        <Wrapper
          ref={ref}
          p={{
            MOBILE: `0 ${SPACE.PX_15}`,
            TABLET: `${SPACE.PX_40} ${SPACE.PX_80}`,
          }}
          alignItems="center"
          role="listbox"
          justifyContent="center"
          data-test-id={DATA_TEST_ID.SEARCH_RESULT_DROPDOWN}
          id={SEARCH_RESULT_DROPDOWN_ID}
          isSearchInputFocused={isSearchInputFocused}
          body-scroll-lock-ignore
        >
          <Box mb={SPACE.PX_15}>
            <Icon.MenuSearch size={ICON_SIZE.PX_20} />
          </Box>
          <Text
            color={COLOR_INTENT.SEARCH.NO_RESULT_TEXT}
            fontSize={FONT_SIZE.PX_14}
            textAlign="center"
            mb={SPACE.PX_10}
          >
            {`No results for “${currentRefinement}”`}
          </Text>
          <Text
            textAlign="center"
            color={COLOR_INTENT.SEARCH.NO_RESULT_SUB_TEXT}
            fontSize={FONT_SIZE.PX_12}
          >
            We couldn&apos;t find any matching experts, categories, products or
            brands.
          </Text>
        </Wrapper>
      )
    }

    return (
      <Wrapper
        ref={ref}
        pb={{ MOBILE: SPACE.PX_100, TABLET: 'unset' }}
        data-test-id={DATA_TEST_ID.SEARCH_RESULT_DROPDOWN}
        id={SEARCH_RESULT_DROPDOWN_ID}
        role="listbox"
        isSearchInputFocused={isSearchInputFocused}
        body-scroll-lock-ignore
      >
        {results.map(
          (index) =>
            index.hits.length > 0 && (
              <Box key={index.indexName}>
                <OptionTitle>
                  <span>{index.indexName}</span>
                  <Divider />
                </OptionTitle>
                {index.hits.map((hit, tabIndex) => (
                  <SearchResultDropdownOption
                    key={hit.objectID}
                    tabIndex={tabIndex}
                    hit={hit}
                    indexName={
                      index.indexName as (typeof INDEX_NAMES)[keyof typeof INDEX_NAMES]
                    }
                    onClick={() => {
                      onSelect(hit)
                    }}
                  />
                ))}
              </Box>
            )
        )}
      </Wrapper>
    )
  }
)
