import { Field, Form, Formik } from 'formik'
import React from 'react'
import * as Yup from 'yup'
import { Button, BUTTON_VARIANT } from 'components/Button'
import { Input } from 'components/Input'
import { Box, Flex } from 'components/Layout'
import { Toast } from 'components/Toast'
import {
  GA4_EVENTS,
  GA4_NEWSLETTER_AREAS,
  useGoogleAnalytics,
} from 'providers/googleAnalytics'
import { SPACE } from 'Theme'
import { useSubscribeToMailingListMutation } from 'types/graphql-generated'

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .email('Enter email address in correct format')
    .required('Enter email address in correct format'),
})

export const NewsletterSubscribeForm = () => {
  const [
    executeSubscribeToMailingListMutation,
    { loading: isSubscribeLoading },
  ] = useSubscribeToMailingListMutation()
  const { executeDataToDataLayer } = useGoogleAnalytics()

  return (
    <Formik
      initialValues={{
        email: '',
      }}
      validateOnChange={false}
      validationSchema={validationSchema}
      onSubmit={async ({ email, ...restValues }, { resetForm }) => {
        const response = await executeSubscribeToMailingListMutation({
          variables: { input: { email: email.trim(), ...restValues } },
        })
        if (!response.errors) {
          Toast.success('You have been successfully subscribed.')
          executeDataToDataLayer({
            event: GA4_EVENTS.NEWSLETTER_SUBSCRIBE,
            overrideData: {
              page_area: GA4_NEWSLETTER_AREAS.FOOTER,
            },
          })
          resetForm()
        }
      }}
    >
      {() => (
        <Form>
          <Flex
            flexDirection={{ MOBILE: 'row', TABLET: 'column', DESKTOP: 'row' }}
            gap={SPACE.PX_8}
          >
            <Field name="email">
              {({ field, meta }) => (
                <Input
                  placeholder="Your email"
                  hint={meta.error ? meta.error : null}
                  hasHint={meta.error !== undefined}
                  isError={meta.touched && meta.error !== undefined}
                  isLoading={isSubscribeLoading}
                  hintMessageContrast="light"
                  aria-label="Newsletter subscribe input"
                  {...field}
                />
              )}
            </Field>
            <Box
              width={{
                MOBILE: 'fit-content',
                TABLET: '100%',
                DESKTOP: 'fit-content',
              }}
            >
              <Button
                type="submit"
                variant={BUTTON_VARIANT.FILLED}
                isLoading={isSubscribeLoading}
              >
                Subscribe
              </Button>
            </Box>
          </Flex>
        </Form>
      )}
    </Formik>
  )
}
