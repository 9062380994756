import { useRouter } from 'next/router'
import { rem } from 'polished'
import React from 'react'
import styled from 'styled-components'
import { Divider } from 'components/Divider'
import { Container, Flex, Grid } from 'components/Layout'
import { ROUTE } from 'constants/routes'
import { COLOR_INTENT, SPACE } from 'Theme'
import { useMedia } from 'useMedia'
import { AgreementLinks } from './AgreementLinks'
import { Copyright } from './Copyright'
import { NavigationLinks } from './NavigationLinks'
import { NewsletterSubscribe } from './NewsletterSubscribe'
import { SocialLinks } from './SocialLinks'

const NEWSLETTER_TABLET_MAX_WIDTH = rem(240)
const NEWSLETTER_DESKTOP_MAX_WIDTH = rem(360)

const MAIN_FOOTER_SECTION_MIN_HEIGHT = rem(200)

const Wrapper = styled.footer`
  display: flex;
  color: ${COLOR_INTENT.FOOTER.WRAPPER.TEXT};
  background-color: ${COLOR_INTENT.FOOTER.WRAPPER.BACKGROUND};
  ${({ isMobile, isMinimal }) =>
    isMobile
      ? `padding: ${SPACE.PX_32} 0 ${SPACE.PX_24} 0;`
      : `padding: ${isMinimal ? SPACE.PX_24 : SPACE.PX_48} 0 ${
          SPACE.PX_24
        } 0;`};
`

const MINIMAL_FOOTER_LINKS = [
  ROUTE.SESSION_CHECKOUT(),
  ROUTE.COUPON_CHECKOUT(),
  ROUTE.SHOWROOM_CHECKOUT_CONTACT(),
  ROUTE.SHOWROOM_CHECKOUT_SHIPPING(),
  ROUTE.TRADE(),
]

export const Footer = () => {
  const { MOBILE: isMobile } = useMedia()
  const { asPath } = useRouter()
  const [asPathWithoutQueryParams] = asPath.split('?')

  // Minimal footer for checkout routes
  if (
    MINIMAL_FOOTER_LINKS.some((link) =>
      asPathWithoutQueryParams.startsWith(link)
    )
  ) {
    return (
      <Wrapper isMobile={isMobile} isMinimal>
        <Container>
          <Flex
            flexDirection={{ MOBILE: 'column', TABLET: 'row' }}
            alignItems="center"
            justifyContent="space-between"
          >
            {isMobile && (
              <>
                <AgreementLinks />
                <Divider mt={SPACE.PX_32} mb={SPACE.PX_24} />
              </>
            )}
            <Copyright />
            {!isMobile && <AgreementLinks />}
          </Flex>
        </Container>
      </Wrapper>
    )
  }

  return (
    <Wrapper isMobile={isMobile} isMinimal={false}>
      <Container>
        {isMobile ? (
          <>
            <NewsletterSubscribe />
            <NavigationLinks />
          </>
        ) : (
          <Grid
            gridTemplateColumns={{
              TABLET: `1fr ${NEWSLETTER_TABLET_MAX_WIDTH}`,
              DESKTOP: `1fr ${NEWSLETTER_DESKTOP_MAX_WIDTH}`,
            }}
            gridColumnGap={SPACE.PX_16}
            // Needs min-width so it does not shift the layout when error message appears below input
            minHeight={MAIN_FOOTER_SECTION_MIN_HEIGHT}
          >
            <NavigationLinks />
            <NewsletterSubscribe />
          </Grid>
        )}
        {isMobile && (
          <>
            <Divider mt={SPACE.PX_48} mb={SPACE.PX_32} />
            <Flex flexDirection="column" gap={SPACE.PX_32}>
              <SocialLinks />
              <AgreementLinks />
            </Flex>
          </>
        )}
        <Divider mt={SPACE.PX_32} mb={SPACE.PX_24} />
        {isMobile ? (
          <Copyright />
        ) : (
          <Flex
            alignItems="center"
            justifyContent="space-between"
            gap={SPACE.PX_16}
          >
            <Copyright />
            <Flex alignItems="center" gap={SPACE.PX_24}>
              <AgreementLinks />
              <SocialLinks />
            </Flex>
          </Flex>
        )}
      </Container>
    </Wrapper>
  )
}
