import { rem } from 'polished'
import React from 'react'
import styled from 'styled-components'
import { Flex } from 'components/Layout'
import { LinkWithText } from 'components/Link'
import {
  ACCESSIBILITY_SLUG,
  COOKIE_POLICY_SLUG,
  PURCHASE_TERMS_SLUG,
  RETURN_POLICY_SLUG,
} from 'constants/common'
import { ROUTE } from 'constants/routes'
import { COLOR_INTENT, DEVICE, FONT_SIZE, SPACE } from 'Theme'

const StyledLinkWithText = styled(LinkWithText)`
  color: ${COLOR_INTENT.FOOTER.AGREEMENT_LINKS.TEXT};
  font-size: ${FONT_SIZE.PX_12};
  &:hover,
  &:active {
    text-decoration: underline;
  }
`

const AGREEMENT_LINKS_WRAPPER_MAX_WIDTH = rem(parseInt(DEVICE.TABLET) / 2)

export const AgreementLinks = () => (
  <Flex
    maxWidth={{ MOBILE: AGREEMENT_LINKS_WRAPPER_MAX_WIDTH, TABLET: '100%' }}
    flexWrap="wrap"
    justifyContent="center"
    margin={{ MOBILE: '0 auto', TABLET: 'unset' }}
    gap={{ MOBILE: SPACE.PX_12, DESKTOP: SPACE.PX_24 }}
  >
    <StyledLinkWithText href={ROUTE.TERMS_OF_USE()}>
      Terms of Service
    </StyledLinkWithText>
    <StyledLinkWithText href={ROUTE.BLOG_DETAIL({ slug: PURCHASE_TERMS_SLUG })}>
      Purchase Terms
    </StyledLinkWithText>
    <StyledLinkWithText href={ROUTE.BLOG_DETAIL({ slug: RETURN_POLICY_SLUG })}>
      Return Policy
    </StyledLinkWithText>
    <StyledLinkWithText href={ROUTE.PRIVACY_POLICY()}>
      Privacy Policy
    </StyledLinkWithText>
    <StyledLinkWithText href={ROUTE.BLOG_DETAIL({ slug: COOKIE_POLICY_SLUG })}>
      Cookie Policy
    </StyledLinkWithText>
    <StyledLinkWithText href={ROUTE.BLOG_DETAIL({ slug: ACCESSIBILITY_SLUG })}>
      Accessibility
    </StyledLinkWithText>
  </Flex>
)
