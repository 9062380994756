import algoliasearch from 'algoliasearch'
import { environment } from 'constants/environment'

export const algoliaClient = algoliasearch(
  environment.ALGOLIA_APPLICATION_ID,
  environment.ALGOLIA_READ_ONLY_API_KEY,
  {
    timeouts: {
      // set it seconds, see docs: https://www.algolia.com/doc/api-reference/api-methods/configuring-timeouts/#examples
      connect: 15, // for clients with slow internet connections, see related Sentry issue https://the-expert-inc.sentry.io/issues/4609297933/?project=5407569&referrer=github_integration
      read: 15, // for clients with slow internet connections, see related Sentry issue https://the-expert-inc.sentry.io/issues/4609297933/?project=5407569&referrer=github_integration
      write: 30,
    },
  }
)
