import { type AutocompleteRenderState } from 'instantsearch.js/es/connectors/autocomplete/connectAutocomplete'
import { keyBy, sumBy } from 'lodash'
import {
  INDICES_WEIGHTS,
  DROPDOWN_MAX_RESULTS,
  VAGUE_SEARCH_INDICES_WEIGHTS,
  INDEX_NAMES,
  MIN_SEARCH_LENGTH,
} from './constants'

/**
 * @description Function that handles results to be displayed in `SearchResultsDropdown` component
 *
 * @example // In case of currentRefinement.length < MIN_SEARCH_LENGTH
 * @returns `null`
 *
 * @example // In case of indices hits.length sum <= DROPDOWN_MAX_RESULTS
 * @returns `AutocompleteRenderState['indices']` - Passed indices
 *
 * @example // In case of indices hits.length sum > DROPDOWN_MAX_RESULTS
 * // 1. Reduces each index hits to maximum number of hits to display determined by index weight
 * // 2. Pushes missing categories count to expert showrooms index or vice versa
 * // 3. Calculates the remaining hits to be redistributed
 * // 4. Iterates through the indices and redistributes remaining hits -> +1 hit to each index until more results are available and remaining hits > 0
 * @returns `AutocompleteRenderState['indices']` - Indices with redistributed hits
 */
export const getResultsToDisplay = (
  indices: AutocompleteRenderState['indices'],
  currentRefinement: string
): AutocompleteRenderState['indices'] | null => {
  if (currentRefinement.length < MIN_SEARCH_LENGTH) {
    return null
  }

  if (sumBy(indices, 'hits.length') <= DROPDOWN_MAX_RESULTS) {
    return indices
  }

  const INDICIES_RESULTS_WEIGHT =
    currentRefinement.length < 4
      ? VAGUE_SEARCH_INDICES_WEIGHTS
      : INDICES_WEIGHTS

  // Reduce each index hits to maximum number of hits to display
  const indicesWithMaxHits = indices.reduce<AutocompleteRenderState['indices']>(
    (acc, { indexName, hits, ...rest }) => {
      const hitsToDisplay = Math.floor(
        DROPDOWN_MAX_RESULTS * INDICIES_RESULTS_WEIGHT[indexName]
      )

      return [
        ...acc,
        {
          indexName,
          hits: hits.slice(0, hitsToDisplay),
          ...rest,
        },
      ]
    },
    []
  )

  const indicesByIndexNameKey = keyBy(indices, 'indexName')
  const indicesWithMaxHitsByIndexNameKey = keyBy(
    indicesWithMaxHits,
    'indexName'
  )

  /**
   * @description Categories index data returned from Algolia
   */
  const categoriesIndex = indicesByIndexNameKey[INDEX_NAMES.PRODUCT_CATEGORIES]
  /**
   * @description Categories index data with weight number of hits to display
   */
  const categoriesIndexWithMaxHits =
    indicesWithMaxHitsByIndexNameKey[INDEX_NAMES.PRODUCT_CATEGORIES]

  // Push missing expert showrooms count to categories index
  if (categoriesIndex.hits.length > categoriesIndexWithMaxHits.hits.length) {
    const categoriesToPush = categoriesIndex.hits.slice(
      categoriesIndexWithMaxHits.hits.length,
      categoriesIndexWithMaxHits.hits.length
    )

    categoriesIndexWithMaxHits.hits.push(...categoriesToPush)
  }

  // Calculate the remaining hits to be redistributed
  let remainingHits =
    DROPDOWN_MAX_RESULTS - sumBy(indicesWithMaxHits, 'hits.length')

  // Iterate through the indices and redistribute remaining hits
  while (
    remainingHits > 0 &&
    sumBy(indices, 'hits.length') - sumBy(indicesWithMaxHits, 'hits.length')
  ) {
    for (
      let i = 0;
      i < indicesWithMaxHits.length && remainingHits > 0;
      i += 1
    ) {
      const indexWithRemainingHits = indicesWithMaxHits[i]

      const responseIndex = indices.find(
        ({ indexName }) => indexName === indexWithRemainingHits.indexName
      )

      if (INDICIES_RESULTS_WEIGHT[indexWithRemainingHits.indexName] === 0) {
        remainingHits -= 1
        // eslint-disable-next-line no-continue
        continue
      }

      if (responseIndex.hits.length > indexWithRemainingHits.hits.length) {
        indexWithRemainingHits.hits.push(
          responseIndex.hits[indexWithRemainingHits.hits.length]
        )

        remainingHits -= 1
      }
    }
  }

  return indicesWithMaxHits
}
